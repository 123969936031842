import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Clientes } from '../../modelo-cliente/clientes.model';
import { ClientesServices } from '../../modelo-cliente/servicios-clientes.services';
import { customValidatorMondogo } from 'app/appmantenimiento/custom-validator/customValidator.services';

@Component({
	selector: 'app-insertarclientemodal',
	templateUrl: './insertarclientemodal.component.html',
	styleUrls: ['./insertarclientemodal.component.scss']
})

export class InsertarClienteModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public formInsertarCliente: FormGroup;
	

	constructor(private fb: FormBuilder,
		public dialogRef: MatDialogRef <InsertarClienteModalComponent>,
		private servicioCliente:ClientesServices,
		private validatciones:customValidatorMondogo,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "85%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
		
		this.formInsertarCliente = this.fb.group({
			tipo:[1,Validators.compose([Validators.required])],
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
			apellido:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
			telefono:[null,Validators.compose([Validators.minLength(1), Validators.maxLength(9)])],
			celular:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
			ciudad:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
			direccion:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
			estado:[1,null],
			mail:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150),CustomValidators.email])],
			cedula: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10),this.validatciones.digitoVerificador])],
		});
	}


	saveCliente(){
		let clienteDataModel:Clientes=this.formInsertarCliente.value;
		this.servicioCliente.guardarClientes(clienteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						let dataBack = {op:1,data:data.datos} as any;
						this.dialogRef.close(dataBack);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}


	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "85%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	tipo(data:any){
		if(Number(data)===1){
			this.formInsertarCliente.controls['tipo'].setValue(data);
			this.formInsertarCliente.controls['cedula'].setValidators([ Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10),this.validatciones.digitoVerificador])]);
			this.formInsertarCliente.controls['cedula'].setValue('');
			this.formInsertarCliente.controls['cedula'].updateValueAndValidity();
		}else{
			this.formInsertarCliente.controls['tipo'].setValue(data);
			this.formInsertarCliente.controls['cedula'].setValidators([ Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])]);
			this.formInsertarCliente.controls['cedula'].setValue('');
			this.formInsertarCliente.controls['cedula'].updateValueAndValidity();
		}
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};