import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Equipos } from '../../modelo-equipo/equipos.model';
import { EquiposServices } from '../../modelo-equipo/servicios-equipos.services';
import { Modelos } from '../../../reparar-modelos/modelo-modelo/modelos.model';
import { ModelosServices } from '../../../reparar-modelos/modelo-modelo/servicios-modelos.services';

@Component({
	selector: 'app-insertarequipomodal',
	templateUrl: './insertarequipomodal.component.html',
	styleUrls: ['./insertarequipomodal.component.scss']
})

export class InsertarEquipoModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataModelos:any;
	public formInsertarEquipo: FormGroup;

	constructor(private fb: FormBuilder,
		public dialogRef: MatDialogRef <InsertarEquipoModalComponent>,
		private servicioEquipo:EquiposServices,
		private servicioModelo:ModelosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.servicioModelo.obtenerDatosModelos().subscribe((data:any)=>{
			this.dataModelos=data.modelos;
		});
	
		this.formInsertarEquipo = this.fb.group({
			codigounico:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
			tiempouso:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(25)])],
			modelo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
			color:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
			estadoequipo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(15)])],
		});
	}


	saveEquipo(){
		let equipoDataModel:Equipos=this.formInsertarEquipo.value;
		this.servicioEquipo.guardarEquipos(equipoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.dialogRef.close(1);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}


	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};