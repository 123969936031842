import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Asignaciones } from '../../modelo-asignacione/asignaciones.model';
import { AsignacionesServices } from '../../modelo-asignacione/servicios-asignaciones.services';
import { Tecnicos } from '../../../reparar-tecnicos/modelo-tecnico/tecnicos.model';
import { TecnicosServices } from '../../../reparar-tecnicos/modelo-tecnico/servicios-tecnicos.services';
import { Recepciones } from '../../../reparar-recepciones/modelo-recepcione/recepciones.model';
import { RecepcionesServices } from '../../../reparar-recepciones/modelo-recepcione/servicios-recepciones.services';
import { ReportesServices } from 'app/appmantenimiento/reparar-reportes/reportes/reportes.services';
import { ImprimirComponent } from 'app/appmantenimiento/reparar-reportes/imprimir/imprimir.component';
import { localStorageServices } from 'app/appauth/auth/localStorageServices';

@Component({
	selector: 'app-editarasignacionemodal',
	templateUrl: './editarasignacionemodal.component.html',
	styleUrls: ['./editarasignacionemodal.component.scss']
})

export class EditarAsignacioneModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	dataTecnicos=[{'tecnico':''}] as any;
	userdata:any;

	configPDF: MatDialogConfig = {
		disableClose: false,
		width: '70%',
		height: '90%',
		position: {
			top: '5%',
			bottom: '',
			left: '',
			right: ''
		},
		data: { datasend: null }
	};
	
	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarAsignaciones: any,
		public dialogRef: MatDialogRef <EditarAsignacioneModalComponent>,
		private servicioAsignacione:AsignacionesServices,
		public dialog: MatDialog,
		private servicioTecnico:TecnicosServices,
		public localdata:localStorageServices,
		private servicesReportes:ReportesServices,
		private servicioRecepcione:RecepcionesServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {

		console.log(this.dataEditarAsignaciones);
		this.userdata=this.localdata.getValueFromLocalStorage();
		this.dataTecnicos[0].tecnico=this.userdata.datos.conectado;
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("90%", "80%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("90%", "80%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	confirmar(){
		let data={'val':this.dataEditarAsignaciones.recepcione.codigo,'msg':'Confirmar revición'};
		this.mensajes.msgConfir(data).then((datas)=>{
			if(datas){
				let dataUpdate = {id:this.dataEditarAsignaciones.id,estado:0} as Asignaciones;

				this.servicioAsignacione.actulizarAsignaciones(dataUpdate).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.mensajes.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.print(this.dataEditarAsignaciones.recepcione);
								this.dialogRef.close(1);
							}
						});
					}else{
						this.mensajes.setNotification(smsdata);
					}
				});
			}
		});
	}

	print(data:any){
		console.log(data);
		var svg = document.querySelector('svg');
		// get svg data
		var xml = new XMLSerializer().serializeToString(svg);

		// make it base64
		var svg64 = btoa(xml);
		var b64Start = 'data:image/svg+xml;base64,';
		data['img']=b64Start + svg64;
		data['asignaciones']=this.dataTecnicos;
		// prepend a "header"
		console.log(b64Start + svg64);
		this.servicesReportes.generatePdfRecepcionesTecnico(data).subscribe((datapdf:any)=>{
			if(datapdf.estado){
				this.mensajes.setNotification(datapdf).then((datas)=>{
					if(datas){
						this.configPDF.data=datapdf.datos.Reparaciones.ruta;
						let dialogRef = this.dialog.open(ImprimirComponent, this.configPDF);
						dialogRef.afterClosed().subscribe(result => {
							if(result==1)
								console.log(result);
								
								
						});
						
					}
				});
			}else{
				this.mensajes.setNotification(datapdf);
			}
		});

		
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};