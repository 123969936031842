import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Valoraciones} from './valoraciones.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class ValoracionesServices {

	constructor(private http: HttpClient) { }

	obtenerDatosValoraciones():Observable<Valoraciones[]>{
		return this.http.get<Valoraciones[]>(Globals.BASE_URL_API_REST+'valoraciones.json');
	}

	guardarValoraciones(data:Valoraciones){
		return this.http.post(Globals.BASE_URL_API_REST+'valoraciones.json',data);
	}

	eliminarValoraciones(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'valoraciones/'+id+'.json');
	}

	actulizarValoraciones(data:Valoraciones){
		 return this.http.patch(Globals.BASE_URL_API_REST+'valoraciones/'+data.id+'.json',data);
	}

	verValoraciones(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'valoraciones/'+id+'.json');
	}
}