import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {MatInputModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatListModule,
		MatGridListModule,
		MatProgressBarModule,
		MatCheckboxModule,
		MatMenuModule,
		MatButtonToggleModule,
		MatToolbarModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RepuestoserviciosRoutes } from './repuestoservicios.routing';
import { RepuestoservicioListaComponent } from './view-repuestoservicio/repuestoserviciovista.component';
@NgModule({
	imports: [
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	RouterModule.forChild(RepuestoserviciosRoutes),
	MatIconModule,
	MatCardModule,
	MatButtonModule,
	ReactiveFormsModule,
	MatCheckboxModule,
	MatListModule,
	MatProgressBarModule,
	MatMenuModule,
	MatToolbarModule,
	FlexLayoutModule,
	MatInputModule,
	MatButtonToggleModule,
	MatGridListModule,
	NgxDatatableModule
],
declarations: [
	RepuestoservicioListaComponent
],
exports:[
	RepuestoservicioListaComponent
]	
})
export class RepuestoserviciosModule {}
