import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Valoraciones } from '../../modelo-valoracione/valoraciones.model';
import { ValoracionesServices } from '../../modelo-valoracione/servicios-valoraciones.services';
import { Asignaciones } from '../../../reparar-asignaciones/modelo-asignacione/asignaciones.model';
import { AsignacionesServices } from '../../../reparar-asignaciones/modelo-asignacione/servicios-asignaciones.services';
import { Repuestoservicios } from '../../../reparar-repuestoservicios/modelo-repuestoservicio/repuestoservicios.model';
import { RepuestoserviciosServices } from '../../../reparar-repuestoservicios/modelo-repuestoservicio/servicios-repuestoservicios.services';

@Component({
	selector: 'app-editarvaloracionemodal',
	templateUrl: './editarvaloracionemodal.component.html',
	styleUrls: ['./editarvaloracionemodal.component.scss']
})

export class EditarValoracioneModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';

	public formEditarDetalle: FormGroup;
	public editarDetalle:boolean=false;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarValoraciones: any,
		public dialogRef: MatDialogRef <EditarValoracioneModalComponent>,
		private servicioValoracione:ValoracionesServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "40%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.formEditarDetalle= this.fb.group({
			detalle:[this.dataEditarValoraciones.detalle,Validators.compose([Validators.required])]
		});
	
	}

	editValoracioneDetalleEnable(){
		this.editarDetalle=true;
		this.formEditarDetalle=this.fb.group({
			detalle:[this.dataEditarValoraciones.detalle,Validators.compose([Validators.required])]
		});
	}

	editValoracioneDetalleDisable(){
		this.editarDetalle=false;
	}

	saveValoracioneDetalle(){
		let ValoracioneDataModel:Valoraciones=this.formEditarDetalle.value;
		ValoracioneDataModel.id=this.dataEditarValoraciones.id;
		this.dataEditarValoraciones.detalle=ValoracioneDataModel.detalle;
		this.servicioValoracione.actulizarValoraciones(ValoracioneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarDetalle=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "40%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};