import { Component, OnInit,ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices} from '../../../appauth/notification/notificatio.services';
import { Observaciones } from '../modelo-observacione/observaciones.model';
import { ObservacionesServices } from '../modelo-observacione/servicios-observaciones.services';
import { InsertarObservacioneModalComponent } from '../modales-observacione/insertar/insertarobservacionemodal.component';
import { EditarObservacioneModalComponent } from '../modales-observacione/editar/editarobservacionemodal.component';
import { Recepciones } from 'app/appmantenimiento/reparar-recepciones/modelo-recepcione/recepciones.model';

@Component({
	selector: 'ms-observacionevista',
	templateUrl:'./observacionevista.component.html',
	styleUrls: ['./observacionevista.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ObservacioneListaComponent implements OnInit {
	
	@Input() Recepcione:Recepciones;
	rows = [];
	temp = [];

	iconItems = [{
		text: 'Nuevo',
		icon: 'add'
	},{
		text: 'Refrescar',
		icon: 'refresh'
	}];

	config: MatDialogConfig = {
		disableClose: false,
		width: '50%',
		height: '60%',
		position: {
			top: '5%',
			bottom: '',
			left: '',
			right: ''
		},
		data: { datasend: null }
	};

	select(text: string) {
		if(text=='Refrescar'){
			if(this.Recepcione!=undefined){
				this.listaObservaciones();
			}
		}else if(text=='Nuevo'){
			this.openObservacione();
		}
	}

	constructor(private serviciosObservaciones:ObservacionesServices,
		public dialog: MatDialog,
		public mensajes:NotificationServices,
	){}

	ngOnInit(){
		if(this.Recepcione!=undefined){
			this.listaObservaciones();	
		}
	}

	listaObservaciones(){
		this.serviciosObservaciones.verObservacionesPorRecepcion(this.Recepcione).subscribe((data:any)=>{
			this.temp = [...data.observaciones];
			this.rows=data.observaciones;
		});
	}

	openObservacione() {
		let datos={'estado':true,'tipomsg':'warning','msg':{'ok':'Confirmar la revisión del equipo <code>Antes de asiganar Observaciones</code>'}};
		this.config.data=this.Recepcione;
		if(this.Recepcione!=undefined){
			let dialogRef = this.dialog.open(InsertarObservacioneModalComponent, this.config);
			dialogRef.afterClosed().subscribe(result => {
				if(result==1)
					this.listaObservaciones();
			});
		}else{
			this.mensajes.setNotification(datos);
		}
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter((d)=> {
			return d.descripcion.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}

	deleteObservacione(dataObservacione:any){
		let data={'val':dataObservacione.descripcion,'msg':'Eliminar Observacione'};
		this.mensajes.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosObservaciones.eliminarObservaciones(dataObservacione.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.mensajes.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.listaObservaciones();
							}
						});
					}else{
						this.mensajes.setNotification(smsdata);
					}
				});
			}
		});
	}

	editObservacione(data:any) {
		this.config.data=data;
		let dialogRef = this.dialog.open(EditarObservacioneModalComponent, this.config);
		dialogRef.afterClosed().subscribe(result => {
			if(result==1)
				this.listaObservaciones();
		});
	}

}