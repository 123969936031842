import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Recepcionistas } from '../../modelo-recepcionista/recepcionistas.model';
import { RecepcionistasServices } from '../../modelo-recepcionista/servicios-recepcionistas.services';
import { customValidatorMondogo } from 'app/appmantenimiento/custom-validator/customValidator.services';

@Component({
	selector: 'app-editarrecepcionistamodal',
	templateUrl: './editarrecepcionistamodal.component.html',
	styleUrls: ['./editarrecepcionistamodal.component.scss']
})

export class EditarRecepcionistaModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';

	public formEditarNombre: FormGroup;
	public editarNombre:boolean=false;

	public formEditarApellido: FormGroup;
	public editarApellido:boolean=false;

	public formEditarTelefono: FormGroup;
	public editarTelefono:boolean=false;

	public formEditarCelular: FormGroup;
	public editarCelular:boolean=false;

	public formEditarCiudad: FormGroup;
	public editarCiudad:boolean=false;

	public formEditarDireccion: FormGroup;
	public editarDireccion:boolean=false;

	public formEditarMail: FormGroup;
	public editarMail:boolean=false;

	public formEditarCedula: FormGroup;
	public editarCedula:boolean=false;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarRecepcionistas: any,
		public dialogRef: MatDialogRef <EditarRecepcionistaModalComponent>,
		private servicioRecepcionista:RecepcionistasServices,
		private validatciones:customValidatorMondogo,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.formEditarNombre= this.fb.group({
			nombre:[this.dataEditarRecepcionistas.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(20)])],
		});
	
		this.formEditarApellido= this.fb.group({
			apellido:[this.dataEditarRecepcionistas.apellido,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	
		this.formEditarTelefono= this.fb.group({
			telefono:[this.dataEditarRecepcionistas.telefono,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
		});
	
		this.formEditarCelular= this.fb.group({
			celular:[this.dataEditarRecepcionistas.celular,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	
		this.formEditarCiudad= this.fb.group({
			ciudad:[this.dataEditarRecepcionistas.ciudad,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(20)])],
		});
	
		this.formEditarDireccion= this.fb.group({
			direccion:[this.dataEditarRecepcionistas.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40),CustomValidators.email])],
		});
	
		this.formEditarMail= this.fb.group({
			mail:[this.dataEditarRecepcionistas.mail,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150),CustomValidators.email])],
		});
	
		this.formEditarCedula= this.fb.group({
			cedula: [this.dataEditarRecepcionistas.cedula, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10),this.validatciones.digitoVerificador])],
		});
	
	}

	editRecepcionistaNombreEnable(){
		this.editarNombre=true;
		this.formEditarNombre=this.fb.group({
			nombre:[this.dataEditarRecepcionistas.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(20)])],
		});
	}

	editRecepcionistaNombreDisable(){
		this.editarNombre=false;
	}

	saveRecepcionistaNombre(){
		let RecepcionistaDataModel:Recepcionistas=this.formEditarNombre.value;
		RecepcionistaDataModel.id=this.dataEditarRecepcionistas.id;
		this.dataEditarRecepcionistas.nombre=RecepcionistaDataModel.nombre;
		this.servicioRecepcionista.actulizarRecepcionistas(RecepcionistaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarNombre=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRecepcionistaApellidoEnable(){
		this.editarApellido=true;
		this.formEditarApellido=this.fb.group({
			apellido:[this.dataEditarRecepcionistas.apellido,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	}

	editRecepcionistaApellidoDisable(){
		this.editarApellido=false;
	}

	saveRecepcionistaApellido(){
		let RecepcionistaDataModel:Recepcionistas=this.formEditarApellido.value;
		RecepcionistaDataModel.id=this.dataEditarRecepcionistas.id;
		this.dataEditarRecepcionistas.apellido=RecepcionistaDataModel.apellido;
		this.servicioRecepcionista.actulizarRecepcionistas(RecepcionistaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarApellido=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRecepcionistaTelefonoEnable(){
		this.editarTelefono=true;
		this.formEditarTelefono=this.fb.group({
			telefono:[this.dataEditarRecepcionistas.telefono,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
		});
	}

	editRecepcionistaTelefonoDisable(){
		this.editarTelefono=false;
	}

	saveRecepcionistaTelefono(){
		let RecepcionistaDataModel:Recepcionistas=this.formEditarTelefono.value;
		RecepcionistaDataModel.id=this.dataEditarRecepcionistas.id;
		this.dataEditarRecepcionistas.telefono=RecepcionistaDataModel.telefono;
		this.servicioRecepcionista.actulizarRecepcionistas(RecepcionistaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarTelefono=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRecepcionistaCelularEnable(){
		this.editarCelular=true;
		this.formEditarCelular=this.fb.group({
			celular:[this.dataEditarRecepcionistas.celular,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	}

	editRecepcionistaCelularDisable(){
		this.editarCelular=false;
	}

	saveRecepcionistaCelular(){
		let RecepcionistaDataModel:Recepcionistas=this.formEditarCelular.value;
		RecepcionistaDataModel.id=this.dataEditarRecepcionistas.id;
		this.dataEditarRecepcionistas.celular=RecepcionistaDataModel.celular;
		this.servicioRecepcionista.actulizarRecepcionistas(RecepcionistaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarCelular=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRecepcionistaCiudadEnable(){
		this.editarCiudad=true;
		this.formEditarCiudad=this.fb.group({
			ciudad:[this.dataEditarRecepcionistas.ciudad,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(20)])],
		});
	}

	editRecepcionistaCiudadDisable(){
		this.editarCiudad=false;
	}

	saveRecepcionistaCiudad(){
		let RecepcionistaDataModel:Recepcionistas=this.formEditarCiudad.value;
		RecepcionistaDataModel.id=this.dataEditarRecepcionistas.id;
		this.dataEditarRecepcionistas.ciudad=RecepcionistaDataModel.ciudad;
		this.servicioRecepcionista.actulizarRecepcionistas(RecepcionistaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarCiudad=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRecepcionistaDireccionEnable(){
		this.editarDireccion=true;
		this.formEditarDireccion=this.fb.group({
			direccion:[this.dataEditarRecepcionistas.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	}

	editRecepcionistaDireccionDisable(){
		this.editarDireccion=false;
	}

	saveRecepcionistaDireccion(){
		let RecepcionistaDataModel:Recepcionistas=this.formEditarDireccion.value;
		RecepcionistaDataModel.id=this.dataEditarRecepcionistas.id;
		this.dataEditarRecepcionistas.direccion=RecepcionistaDataModel.direccion;
		this.servicioRecepcionista.actulizarRecepcionistas(RecepcionistaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarDireccion=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRecepcionistaMailEnable(){
		this.editarMail=true;
		this.formEditarMail=this.fb.group({
			mail:[this.dataEditarRecepcionistas.mail,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150),CustomValidators.email])],
		});
	}

	editRecepcionistaMailDisable(){
		this.editarMail=false;
	}

	saveRecepcionistaMail(){
		let RecepcionistaDataModel:Recepcionistas=this.formEditarMail.value;
		RecepcionistaDataModel.id=this.dataEditarRecepcionistas.id;
		this.dataEditarRecepcionistas.mail=RecepcionistaDataModel.mail;
		this.servicioRecepcionista.actulizarRecepcionistas(RecepcionistaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarMail=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRecepcionistaCedulaEnable(){
		this.editarCedula=true;
		this.formEditarCedula=this.fb.group({
			cedula: [this.dataEditarRecepcionistas.cedula, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10),this.validatciones.digitoVerificador])],
		});
	}

	editRecepcionistaCedulaDisable(){
		this.editarCedula=false;
	}

	saveRecepcionistaCedula(){
		let RecepcionistaDataModel:Recepcionistas=this.formEditarCedula.value;
		RecepcionistaDataModel.id=this.dataEditarRecepcionistas.id;
		this.dataEditarRecepcionistas.cedula=RecepcionistaDataModel.cedula;
		this.servicioRecepcionista.actulizarRecepcionistas(RecepcionistaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarCedula=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};