import { Injectable } from '@angular/core';
import {HttpEvent,HttpInterceptor,HttpHandler,HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { localStorageServices} from '../auth/localStorageServices';
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor{

    constructor(private localdata:localStorageServices,private router: Router){

    }
    intercept(req:HttpRequest<any>,next:HttpHandler): Observable<HttpEvent<any>>{
        console.log('Hola  I am Interceptor');
        
        let tokendata=null;
        if(JSON.stringify(this.localdata.getValueFromLocalStorage())!='null' && JSON.stringify(this.localdata.getValueFromLocalStorage())!='{}' ){
            tokendata=this.localdata.getValueFromLocalStorage().datos.token;
        }

        if(req.headers.get('Authorization')=='SmartRoad 2525258479'){
           return next.handle(req.clone({setHeaders:{Authorization:req.headers.get('Authorization')}}));
        }else{
            let authHeader='SmartRoad '+tokendata;
            const authReq=req.clone({setHeaders:{Authorization:authHeader}});
            return next.handle(authReq);
        }

    }
}