import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Problemas } from '../../modelo-problema/problemas.model';
import { ProblemasServices } from '../../modelo-problema/servicios-problemas.services';
import { Recepciones } from '../../../reparar-recepciones/modelo-recepcione/recepciones.model';
import { RecepcionesServices } from '../../../reparar-recepciones/modelo-recepcione/servicios-recepciones.services';
import { Repuestoservicios } from '../../../reparar-repuestoservicios/modelo-repuestoservicio/repuestoservicios.model';
import { RepuestoserviciosServices } from '../../../reparar-repuestoservicios/modelo-repuestoservicio/servicios-repuestoservicios.services';
import * as moment from 'moment';

@Component({
	selector: 'app-insertarproblemamodal',
	templateUrl: './insertarproblemamodal.component.html',
	styleUrls: ['./insertarproblemamodal.component.scss']
})

export class InsertarProblemaModalComponent implements OnInit {
	rows = [];
	temp = [];
	breakpoint: number;
	minDisplay:any;
	horaDisplay:any;
	diasDisplay:any;
	semanaDisplay:any;
	totalCosto:number=0;
	ratio = '4:1';
	public dataRecepciones:any;
	public dataRepuestoservicios:any;
	public formInsertarProblema: FormGroup;
	arrayInsertarRepuestoServicio=[];

	constructor(private fb: FormBuilder,
		public dialogRef: MatDialogRef <InsertarProblemaModalComponent>,
		@Inject(MAT_DIALOG_DATA) public dataInsertProblemas: any,
		private servicioProblema:ProblemasServices,
		private servicioRecepcione:RecepcionesServices,
		private servicioRepuestoservicio:RepuestoserviciosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
		console.log(this.dataInsertProblemas);
		this.dataInsertProblemas.modelo.recepcione_id=this.dataInsertProblemas.recepcion.id;

		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("70%", "85%");
		}else{
			this.dialogRef.updateSize("100%", "90%");
		}
	
		this.servicioRecepcione.obtenerDatosRecepciones().subscribe((data:any)=>{
			this.dataRecepciones=data.recepciones;
		});
		this.servicioRepuestoservicio.obtenerDatosRepuestoservicios().subscribe((data:any)=>{
			this.dataRepuestoservicios=data.repuestoservicios;
		});
	
		this.formInsertarProblema = this.fb.group({
			descripcion:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
			estado:[1,null],
			recepcione_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			repuestoservicio_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}


	saveProblema(){
		let problemaDataModel:Problemas[]=[];
		this.arrayInsertarRepuestoServicio.forEach(element => {
			let prob =  {} as Problemas;
			prob.repuestoservicio_id=element.id;
			prob.recepcione_id=this.dataInsertProblemas.recepcion.id;
			prob.estado=1;
			problemaDataModel.push(prob);
		});
		this.servicioProblema.guardarProblemas(problemaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.dialogRef.close(1);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}


	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("70%", "85%");
		}else{
			this.dialogRef.updateSize("100%", "90%");
		}
	}

	verProblemasServiciosSelecionados(data:any){
		this.arrayInsertarRepuestoServicio=data;
		console.log('lista',this.arrayInsertarRepuestoServicio);
		
		this.suma();
		this.tiempo();
		
	}

	selccion(data:any){
		var i = this.arrayInsertarRepuestoServicio.indexOf( data );
		this.arrayInsertarRepuestoServicio.splice( i, 1 );
		this.suma();
		this.tiempo();
	}

	suma(){
		this.totalCosto=0;
		this.arrayInsertarRepuestoServicio.forEach(element => {
			this.totalCosto+=element.precio;
		});
	}

	tiempo(){

		let min=0;
		let hora=0;
		let dias=0;
		let seman=0;
		this.arrayInsertarRepuestoServicio.forEach(element => {
			if(element.tipo==1){
				let tmpd= moment.utc(element.tiempo).format('YYYY-MM-DD HH:mm:ss')
				let resul =(tmpd.split(' ')[1].split(':'));
				min+=Number(resul[1]);
				hora+=Number(resul[0]);
			} 
			if(element.tipo==2){
				dias+=element.valor;
			}
			if(element.tipo==3){
				seman+=element.valor;
			}
		});

		
		if(min>59){
			let mt=0,v=0,v1=0;	
			v=Math.trunc(min/60);
			v1=v*60;
			mt=min-v1;
			hora=hora+v;
			min=mt;
		}	
		if(hora>8){
			let ht=0,v=0,v1=0;	
			v=Math.trunc(hora/8);
			v1=v*8;
			ht=hora-v1;
			dias=dias+v;
			hora=ht;
		}
		if(dias>5){
			let st=0,v=0,v1=0;	
			v=Math.trunc(dias/5);
			v1=v*5;
			st=dias-v1;
			seman=seman+v;
			dias=st;
		}

		this.minDisplay=min;
		this.horaDisplay=hora;
		this.diasDisplay=dias;
		this.semanaDisplay=seman;
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};