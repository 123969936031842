import { Component, OnInit } from '@angular/core';
import { RecepcionistasServices } from '../../reparar-recepcionistas/modelo-recepcionista/servicios-recepcionistas.services';
import { ReportesServices } from '../reportes/reportes.services';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ImprimirComponent } from '../imprimir/imprimir.component';

@Component({
  selector: 'app-listadiarias',
  templateUrl: './listadiarias.component.html',
  styleUrls: ['./listadiarias.component.scss']
})
export class ListadiariasComponent implements OnInit {

  rows = [];
	temp = [];
	rowsec = [];
	tempec = [];
	rowsecod = [];
  tempecod = [];
  
  minimo:Date;
  maximo:Date;
  recepcionista:number;
  public dataRecepcionistas:any;
  public imgUrl:string;
  userdata:any;
  breakpoint: number;
  ratio = '4:1.2';
  constructor( private servicioRecepcionista:RecepcionistasServices,
               public mensajes: NotificationServices,
               public dialog: MatDialog,
               private servicioDias:ReportesServices) { }

  ngOnInit() {
    this.servicioRecepcionista.obtenerDatosRecepcionistas().subscribe((data:any)=>{
			this.dataRecepcionistas=data.recepcionistas;
    });
    this.breakpoint = (window.innerWidth <= 800) ? 1 : 4;
		this.ratio=(window.innerWidth <= 800) ? '4:1.3':'4:1.2';
  }

  configPDF: MatDialogConfig = {
		disableClose: false,
		width: '70%',
		height: '90%',
		position: {
			top: '5%',
			bottom: '',
			left: '',
			right: ''
		},
		data: { datasend: null }
	};
	

  buscar(){
    let dataSendNotificacion = {} as any;
    
    if(this.minimo){
      dataSendNotificacion.minimo=this.minimo;
      if(this.minimo<this.maximo){
        dataSendNotificacion.maximo=this.maximo; 
      }
    }
    if(this.recepcionista){
      dataSendNotificacion.recepcionista_id=this.recepcionista;
    }
    console.log(dataSendNotificacion);
    
    this.servicioDias.generatePorDia(dataSendNotificacion).subscribe((data:any)=>{
      console.log(data);
      this.rowsecod=data.recepciones;
			this.tempecod = [...this.rowsecod];
    });
    
  }

  print(){
    let dataSendNotificacion = {} as any;
    
    if(this.minimo){
      dataSendNotificacion.minimo=this.minimo;
      if(this.minimo<this.maximo){
        dataSendNotificacion.maximo=this.maximo; 
      }
    }
    if(this.recepcionista){
      dataSendNotificacion.recepcionista_id=this.recepcionista;
    }
    console.log(dataSendNotificacion);
		this.servicioDias.generatePdfRecepcionesPorDia(dataSendNotificacion).subscribe((datapdf:any)=>{
			if(datapdf.estado){
				this.mensajes.setNotification(datapdf).then((datas)=>{
					if(datas){
						this.configPDF.data=datapdf.datos.Recepciones.ruta;
						let dialogRef = this.dialog.open(ImprimirComponent, this.configPDF);
						dialogRef.afterClosed().subscribe(result => {
							if(result==1)
								console.log(result);
								
						});
						
					}
				});
			}else{
				this.mensajes.setNotification(datapdf);
			}
		});

		
	}

}
