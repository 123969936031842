import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {MatInputModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatListModule,
		MatGridListModule,
		MatProgressBarModule,
		MatMenuModule,
		MatButtonToggleModule,
		MatToolbarModule, 
		MatTabsModule} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ObservacionesRoutes } from './observaciones.routing';
import { ObservacioneListaComponent } from './view-observacione/observacionevista.component';
import { InfoobservacionesComponent } from './infoobservaciones/infoobservaciones.component';
@NgModule({
	imports: [
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	RouterModule.forChild(ObservacionesRoutes),
	MatIconModule,
	MatCardModule,
	MatButtonModule,
	ReactiveFormsModule,
	MatListModule,
	MatProgressBarModule,
	MatMenuModule,
	MatToolbarModule,
	FlexLayoutModule,
	MatInputModule,
	MatButtonToggleModule,
	MatTabsModule,
	MatGridListModule,
	NgxDatatableModule
],
declarations: [
	ObservacioneListaComponent,
	InfoobservacionesComponent
],
exports: [
	ObservacioneListaComponent,
	InfoobservacionesComponent
]
})
export class ObservacionesModule {}
