import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Modelos } from 'app/appmantenimiento/reparar-modelos/modelo-modelo/modelos.model';
import { Marcas } from 'app/appmantenimiento/reparar-marcas/modelo-marca/marcas.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModelosServices } from '../../modelo-modelo/servicios-modelos.services';
import { NotificationServices } from 'app/appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';

@Component({
  selector: 'app-nuevomodelorecepcion',
  templateUrl: './nuevomodelorecepcion.component.html',
  styleUrls: ['./nuevomodelorecepcion.component.scss']
})
export class NuevomodelorecepcionComponent implements OnInit {

  public formInsertarModelo: FormGroup;
  @Input() Marca:Marcas;
  @Output() valueChange = new EventEmitter();

  constructor(private fb: FormBuilder,
		private servicioModelo:ModelosServices,
		private mensajes:NotificationServices,) { }

  ngOnInit() {
    console.log(this.Marca);
    
    this.formInsertarModelo = this.fb.group({
      nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
      marca_id:[this.Marca.id,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
  }

  saveModelo(){
		let ModeloDataModel:Modelos=this.formInsertarModelo.value;
		this.servicioModelo.guardarModelos(ModeloDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas:any)=>{
					if(datas){
            this.formInsertarModelo = this.fb.group({
              nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
              marca_id:[this.Marca.id,Validators.compose([Validators.required,CustomValidators.number])],
              estado:[1,null],
            });
            let dataBack = {op:1,data:data.datos} as any;
						this.valueChange.emit(dataBack); 
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
  }
  
  cerrarModal(){
    let dataBack = {op:2,data:{}} as any;
    this.valueChange.emit(dataBack); 
  }

}
