import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Repuestosvaloraciones} from './repuestosvaloraciones.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class RepuestosvaloracionesServices {

	constructor(private http: HttpClient) { }

	obtenerDatosRepuestosvaloraciones():Observable<Repuestosvaloraciones[]>{
		return this.http.get<Repuestosvaloraciones[]>(Globals.BASE_URL_API_REST+'repuestosvaloraciones.json');
	}

	guardarRepuestosvaloraciones(data:Repuestosvaloraciones){
		return this.http.post(Globals.BASE_URL_API_REST+'repuestosvaloraciones.json',data);
	}

	eliminarRepuestosvaloraciones(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'repuestosvaloraciones/'+id+'.json');
	}

	actulizarRepuestosvaloraciones(data:Repuestosvaloraciones){
		 return this.http.patch(Globals.BASE_URL_API_REST+'repuestosvaloraciones/'+data.id+'.json',data);
	}

	verRepuestosvaloraciones(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'repuestosvaloraciones/'+id+'.json');
	}
}