import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FotoTecnicosComponent } from './foto/fototecnico.compente';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[FotoTecnicosComponent],
	entryComponents:[FotoTecnicosComponent],
})
export class ModalFotoTecnicosModule { }
