import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {MatInputModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatListModule,
		MatGridListModule,
		MatProgressBarModule,
		MatMenuModule,
		MatButtonToggleModule,
		MatTabsModule,
		MatSelectModule,
		MatTableModule,
		MatRadioModule,
		MatToolbarModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EditarRecepcioneModalComponent } from './editar/editarrecepcionemodal.component';
import { InsertarRecepcioneModalComponent } from './insertar/insertarrecepcionemodal.component';
@NgModule({
	declarations: [
		EditarRecepcioneModalComponent,
		InsertarRecepcioneModalComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatListModule,
		ReactiveFormsModule,
		MatProgressBarModule,
		MatMenuModule,
		MatToolbarModule,
		FlexLayoutModule,
		MatButtonToggleModule,
		MatGridListModule,
		MatSelectModule,
		MatRadioModule,
		MatTabsModule,
		MatTableModule,
		NgxDatatableModule
	],
	entryComponents: [
		EditarRecepcioneModalComponent,
		InsertarRecepcioneModalComponent,
	]
})
export class RecepcionesModalModule {}
