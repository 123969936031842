import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Marcas } from '../../modelo-marca/marcas.model';
import { MarcasServices } from '../../modelo-marca/servicios-marcas.services';
import { Dispositivos } from '../../../reparar-dispositivos/modelo-dispositivo/dispositivos.model';
import { DispositivosServices } from '../../../reparar-dispositivos/modelo-dispositivo/servicios-dispositivos.services';

@Component({
	selector: 'app-insertarmarcamodal',
	templateUrl: './insertarmarcamodal.component.html',
	styleUrls: ['./insertarmarcamodal.component.scss']
})

export class InsertarMarcaModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataDispositivos:any;
	public formInsertarMarca: FormGroup;

	constructor(private fb: FormBuilder,
		public dialogRef: MatDialogRef <InsertarMarcaModalComponent>,
		private servicioMarca:MarcasServices,
		private servicioDispositivo:DispositivosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "35%");
		}else{
			this.dialogRef.updateSize("100%", "60%");
		}
	
		this.servicioDispositivo.obtenerDatosDispositivos().subscribe((data:any)=>{
			this.dataDispositivos=data.dispositivos;
		});
	
		this.formInsertarMarca = this.fb.group({
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
			estado:[1,null],
			dispositivo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}


	saveMarca(){
		let marcaDataModel:Marcas=this.formInsertarMarca.value;
		this.servicioMarca.guardarMarcas(marcaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.dialogRef.close(1);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}


	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "35%");
		}else{
			this.dialogRef.updateSize("100%", "60%");
		}
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};