import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FotoEquiposComponent } from './foto/fotoequipo.compente';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[FotoEquiposComponent],
	entryComponents:[FotoEquiposComponent],
})
export class ModalFotoEquiposModule { }
