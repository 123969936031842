import { Routes } from '@angular/router';
import { AuthGuard } from '../../appauth/auth/auth.guard';
import { ListadiariasComponent } from './listadiarias/listadiarias.component';

export const ReportesRoutes: Routes = [{
	path: '',
	redirectTo: 'lista-diarias',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'lista-diarias',
		component: ListadiariasComponent,
		canActivate: [AuthGuard]
	}]
}];
