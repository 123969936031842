import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Repuestoservicios } from '../../modelo-repuestoservicio/repuestoservicios.model';
import { RepuestoserviciosServices } from '../../modelo-repuestoservicio/servicios-repuestoservicios.services';
import { Modelos } from '../../../reparar-modelos/modelo-modelo/modelos.model';
import { ModelosServices } from '../../../reparar-modelos/modelo-modelo/servicios-modelos.services';
import * as moment from 'moment';

@Component({
	selector: 'app-editarrepuestoserviciomodal',
	templateUrl: './editarrepuestoserviciomodal.component.html',
	styleUrls: ['./editarrepuestoserviciomodal.component.scss']
})

export class EditarRepuestoservicioModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataModelos:any;
	valueTiempo:number=0;

	public formEditarNombre: FormGroup;
	public editarNombre:boolean=false;

	public formEditarTiempo: FormGroup;
	public editarTiempo:boolean=false;

	public formEditarPrecio: FormGroup;
	public editarPrecio:boolean=false;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarRepuestoservicios: any,
		public dialogRef: MatDialogRef <EditarRepuestoservicioModalComponent>,
		private servicioRepuestoservicio:RepuestoserviciosServices,
		private servicioModelo:ModelosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
		this.valueTiempo=this.dataEditarRepuestoservicios.tipo;
		this.formEditarNombre= this.fb.group({
			nombre:[this.dataEditarRepuestoservicios.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});

		this.formEditarTiempo= this.fb.group({
			tiempo:[this.dataEditarRepuestoservicios.tiempo,Validators.compose([Validators.required])],
			tipo:[this.dataEditarRepuestoservicios.tipo,Validators.compose([Validators.required,CustomValidators.number])],
			min:[0,Validators.compose([Validators.required,CustomValidators.number])],
		});
	
		this.formEditarPrecio= this.fb.group({
			precio:[this.dataEditarRepuestoservicios.precio,Validators.compose([Validators.required,CustomValidators.number])],
		});
	
	}

	editRepuestoservicioNombreEnable(){
		this.editarNombre=true;
		this.formEditarNombre=this.fb.group({
			nombre:[this.dataEditarRepuestoservicios.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	}

	editRepuestoservicioNombreDisable(){
		this.editarNombre=false;
	}

	saveRepuestoservicioNombre(){
		let RepuestoservicioDataModel:Repuestoservicios=this.formEditarNombre.value;
		RepuestoservicioDataModel.id=this.dataEditarRepuestoservicios.id;
		this.dataEditarRepuestoservicios.nombre=RepuestoservicioDataModel.nombre;
		this.servicioRepuestoservicio.actulizarRepuestoservicios(RepuestoservicioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						let dataReturn = {op:1,datos:data.datos}
						this.dialogRef.close(dataReturn);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRepuestoservicioTiempoEnable(){
		this.editarTiempo=true;
		if(this.dataEditarRepuestoservicios.tipo==1){
			let min=0;
			let hora=0;
			let tmpd= moment.utc(this.dataEditarRepuestoservicios.tiempo).format('YYYY-MM-DD HH:mm:ss')
			let resul =(tmpd.split(' ')[1].split(':'));
			min+=Number(resul[1]);
			hora+=Number(resul[0]);
			this.formEditarTiempo=this.fb.group({
				tiempo:[hora,Validators.compose([Validators.required])],
				tipo:[this.dataEditarRepuestoservicios.tipo,Validators.compose([Validators.required,CustomValidators.number])],
				min:[min,Validators.compose([Validators.required,CustomValidators.number])],
			});
		}else if(this.dataEditarRepuestoservicios.tipo==2){
			this.formEditarTiempo=this.fb.group({
				tiempo:[this.dataEditarRepuestoservicios.valor,Validators.compose([Validators.required])],
				tipo:[this.dataEditarRepuestoservicios.tipo,Validators.compose([Validators.required,CustomValidators.number])],
				min:[0,Validators.compose([Validators.required,CustomValidators.number])],
			});
		}else{
			this.formEditarTiempo=this.fb.group({
				tiempo:[this.dataEditarRepuestoservicios.valor,Validators.compose([Validators.required])],
				tipo:[this.dataEditarRepuestoservicios.tipo,Validators.compose([Validators.required,CustomValidators.number])],
				min:[0,Validators.compose([Validators.required,CustomValidators.number])],
			});
		}
		
	}

	editRepuestoservicioTiempoDisable(){
		this.editarTiempo=false;
	}

	saveRepuestoservicioTiempo(){
		let RepuestoservicioDataModel:any=this.formEditarTiempo.value;
		RepuestoservicioDataModel.id=this.dataEditarRepuestoservicios.id;
		this.dataEditarRepuestoservicios.tiempo=RepuestoservicioDataModel.tiempo;
		this.dataEditarRepuestoservicios.tipo=RepuestoservicioDataModel.tipo;
		if(RepuestoservicioDataModel.tipo==1){
			RepuestoservicioDataModel.tiempo= moment('2000-01-01').add(moment.duration(RepuestoservicioDataModel.tiempo+':'+RepuestoservicioDataModel.min));
			RepuestoservicioDataModel.tiempo= moment(RepuestoservicioDataModel.tiempo).format('YYYY-MM-DD HH:mm:ss')
		}else if(RepuestoservicioDataModel.tipo==2){
			console.log(RepuestoservicioDataModel.tiempo);
			
			RepuestoservicioDataModel.tiempo=moment('2000-01-01').add(RepuestoservicioDataModel.tiempo, 'days');
		}else{

			console.log(RepuestoservicioDataModel.tiempo);
			RepuestoservicioDataModel.tiempo=moment('2000-01-01').add(RepuestoservicioDataModel.tiempo*7, 'days');
		}
		this.servicioRepuestoservicio.actulizarRepuestoservicios(RepuestoservicioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						let dataReturn = {op:1,datos:data.datos}
						this.dialogRef.close(dataReturn);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRepuestoservicioPrecioEnable(){
		this.editarPrecio=true;
		this.formEditarPrecio=this.fb.group({
			precio:[this.dataEditarRepuestoservicios.precio,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editRepuestoservicioPrecioDisable(){
		this.editarPrecio=false;
	}

	saveRepuestoservicioPrecio(){
		let RepuestoservicioDataModel:Repuestoservicios=this.formEditarPrecio.value;
		RepuestoservicioDataModel.id=this.dataEditarRepuestoservicios.id;
		this.dataEditarRepuestoservicios.precio=RepuestoservicioDataModel.precio;
		this.servicioRepuestoservicio.actulizarRepuestoservicios(RepuestoservicioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						let dataReturn = {op:1,datos:data.datos}
						this.dialogRef.close(dataReturn);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	setValor(val:number){
		this.valueTiempo=val;
	}

	ceros(e:any){
		if (e.target.value.length >= 2) e.target.value = e.target.value.slice(0, 2);
		if (e.target.value.length === 1) e.target.value = '0' + e.target.value;
		if (!e.target.value) e.target.value = '00';
	}
	maximo(e:any){
		if (e.target.value > 5) e.target.value = 5;
		if (e.target.value < 1) e.target.value = 1;
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};