import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Marcas } from 'app/appmantenimiento/reparar-marcas/modelo-marca/marcas.model';
import { NotificationServices } from 'app/appauth/notification/notificatio.services';
import { MarcasServices } from 'app/appmantenimiento/reparar-marcas/modelo-marca/servicios-marcas.services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Dispositivos } from 'app/appmantenimiento/reparar-dispositivos/modelo-dispositivo/dispositivos.model';

@Component({
  selector: 'app-nuevomarcarecepcion',
  templateUrl: './nuevomarcarecepcion.component.html',
  styleUrls: ['./nuevomarcarecepcion.component.scss']
})
export class NuevomarcarecepcionComponent implements OnInit {

  public formInsertarMarca: FormGroup;
  @Input() Dispositivo:Dispositivos;
  @Output() valueChange = new EventEmitter();

  constructor(private fb: FormBuilder,
		private servicioMarca:MarcasServices,
		private mensajes:NotificationServices,) { }

  ngOnInit() {
    console.log(this.Dispositivo);
    
    this.formInsertarMarca = this.fb.group({
      nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
      dispositivo_id:[this.Dispositivo.id,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
  }

  saveMarca(){
		let MarcaDataModel:Marcas=this.formInsertarMarca.value;
		this.servicioMarca.guardarMarcas(MarcaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas:any)=>{
					if(datas){
            let dataBack = {op:1,data:data.datos} as any;
						this.valueChange.emit(dataBack); 
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
  }
  
  cerrarModal(){
    let dataBack = {op:2,data:{}} as any;
    this.valueChange.emit(dataBack); 
  }


}
