import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DispositivosServices } from 'app/appmantenimiento/reparar-dispositivos/modelo-dispositivo/servicios-dispositivos.services';
import { NotificationServices } from 'app/appauth/notification/notificatio.services';
import { Dispositivos } from 'app/appmantenimiento/reparar-dispositivos/modelo-dispositivo/dispositivos.model';

@Component({
  selector: 'app-nuevorecepcion',
  templateUrl: './nuevorecepcion.component.html',
  styleUrls: ['./nuevorecepcion.component.scss']
})
export class NuevorecepcionComponent implements OnInit {

  public formInsertarDispositivo: FormGroup;

  @Output() valueChange = new EventEmitter();

  constructor(private fb: FormBuilder,
		private servicioDispositivo:DispositivosServices,
		private mensajes:NotificationServices,) { }

  ngOnInit() {
    this.formInsertarDispositivo = this.fb.group({
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
			estado:[1,null],
		});
  }

  saveDispositivo(){
		let dispositivoDataModel:Dispositivos=this.formInsertarDispositivo.value;
		this.servicioDispositivo.guardarDispositivos(dispositivoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas:any)=>{
					if(datas){
            			let dataBack = {op:1,data:data.datos} as any;
						this.valueChange.emit(dataBack); 
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
  }
  
  cerrarModal(){
    let dataBack = {op:2,data:{}} as any;
    this.valueChange.emit(dataBack); 
  }


}
