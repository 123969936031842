import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Recepciones} from './recepciones.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class RecepcionesServices {

	constructor(private http: HttpClient) { }

	obtenerDatosRecepciones():Observable<Recepciones[]>{
		return this.http.get<Recepciones[]>(Globals.BASE_URL_API_REST+'recepciones.json');
	}

	guardarRecepciones(data:Recepciones){
		return this.http.post(Globals.BASE_URL_API_REST+'recepciones.json',data);
	}

	eliminarRecepciones(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'recepciones/'+id+'.json');
	}

	actulizarRecepciones(data:Recepciones){
		 return this.http.patch(Globals.BASE_URL_API_REST+'recepciones/'+data.id+'.json',data);
	}

	verRecepciones(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'recepciones/'+id+'.json');
	}

	verRecepcionesPorCodigo(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listarecpcionecodido.json',data);
	}


	pagoRecepcionesPorCodigo(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'pagoreviciones.json',data);
	}


	pagos(data:Recepciones){
		return this.http.post(Globals.BASE_URL_API_REST+'pagos.json',data);
   	}
	

}