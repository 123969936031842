import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Recepcionistas} from './recepcionistas.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class RecepcionistasServices {

	constructor(private http: HttpClient) { }

	obtenerDatosRecepcionistas():Observable<Recepcionistas[]>{
		return this.http.get<Recepcionistas[]>(Globals.BASE_URL_API_REST+'recepcionistas.json');
	}

	guardarRecepcionistas(data:Recepcionistas){
		return this.http.post(Globals.BASE_URL_API_REST+'recepcionistas.json',data);
	}

	eliminarRecepcionistas(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'recepcionistas/'+id+'.json');
	}

	actulizarRecepcionistas(data:Recepcionistas){
		 return this.http.patch(Globals.BASE_URL_API_REST+'recepcionistas/'+data.id+'.json',data);
	}

	verRecepcionistas(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'recepcionistas/'+id+'.json');
	}
}