import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Clientes } from '../../modelo-cliente/clientes.model';
import { ClientesServices } from '../../modelo-cliente/servicios-clientes.services';
import { customValidatorMondogo } from 'app/appmantenimiento/custom-validator/customValidator.services';

@Component({
	selector: 'app-editarclientemodal',
	templateUrl: './editarclientemodal.component.html',
	styleUrls: ['./editarclientemodal.component.scss']
})

export class EditarClienteModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';

	public formEditarNombre: FormGroup;
	public editarNombre:boolean=false;

	public formEditarApellido: FormGroup;
	public editarApellido:boolean=false;

	public formEditarTelefono: FormGroup;
	public editarTelefono:boolean=false;

	public formEditarCelular: FormGroup;
	public editarCelular:boolean=false;

	public formEditarCiudad: FormGroup;
	public editarCiudad:boolean=false;

	public formEditarDireccion: FormGroup;
	public editarDireccion:boolean=false;

	public formEditarCedula: FormGroup;
	public editarCedula:boolean=false;
	
	public formEditarMail: FormGroup;
	public editarMail:boolean=false;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarClientes: any,
		public dialogRef: MatDialogRef <EditarClienteModalComponent>,
		private servicioCliente:ClientesServices,
		private validatciones:customValidatorMondogo,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1.5';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "70%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.formEditarNombre= this.fb.group({
			nombre:[this.dataEditarClientes.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	
		this.formEditarApellido= this.fb.group({
			apellido:[this.dataEditarClientes.apellido,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	
		this.formEditarTelefono= this.fb.group({
			telefono:[this.dataEditarClientes.telefono,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
		});
	
		this.formEditarCelular= this.fb.group({
			celular:[this.dataEditarClientes.celular,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	
		this.formEditarCiudad= this.fb.group({
			ciudad:[this.dataEditarClientes.ciudad,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	
		this.formEditarDireccion= this.fb.group({
			direccion:[this.dataEditarClientes.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
		});

		this.formEditarMail= this.fb.group({
			mail:[this.dataEditarClientes.mail,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150),CustomValidators.email])],
		});

		if(this.dataEditarClientes.tipo==1){
			this.formEditarCedula=this.fb.group({
				tipo:[1,Validators.compose([Validators.required])],
				cedula: [this.dataEditarClientes.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
			});
		}else{
			this.formEditarCedula=this.fb.group({
				tipo:[2,Validators.compose([Validators.required])],
				cedula: [this.dataEditarClientes.cedula,Validators.compose([Validators.required,Validators.minLength(5),Validators.maxLength(50)])],
			});
		}
	
	}

	editClienteNombreEnable(){
		this.editarNombre=true;
		this.formEditarNombre=this.fb.group({
			nombre:[this.dataEditarClientes.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	}

	editClienteNombreDisable(){
		this.editarNombre=false;
	}

	saveClienteNombre(){
		let ClienteDataModel:Clientes=this.formEditarNombre.value;
		ClienteDataModel.id=this.dataEditarClientes.id;
		this.dataEditarClientes.nombre=ClienteDataModel.nombre;
		this.servicioCliente.actulizarClientes(ClienteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarNombre=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editClienteApellidoEnable(){
		this.editarApellido=true;
		this.formEditarApellido=this.fb.group({
			apellido:[this.dataEditarClientes.apellido,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	}

	editClienteApellidoDisable(){
		this.editarApellido=false;
	}

	saveClienteApellido(){
		let ClienteDataModel:Clientes=this.formEditarApellido.value;
		ClienteDataModel.id=this.dataEditarClientes.id;
		this.dataEditarClientes.apellido=ClienteDataModel.apellido;
		this.servicioCliente.actulizarClientes(ClienteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarApellido=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editClienteTelefonoEnable(){
		this.editarTelefono=true;
		this.formEditarTelefono=this.fb.group({
			telefono:[this.dataEditarClientes.telefono,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
		});
	}

	editClienteTelefonoDisable(){
		this.editarTelefono=false;
	}

	saveClienteTelefono(){
		let ClienteDataModel:Clientes=this.formEditarTelefono.value;
		ClienteDataModel.id=this.dataEditarClientes.id;
		this.dataEditarClientes.telefono=ClienteDataModel.telefono;
		this.servicioCliente.actulizarClientes(ClienteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarTelefono=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editClienteCelularEnable(){
		this.editarCelular=true;
		this.formEditarCelular=this.fb.group({
			celular:[this.dataEditarClientes.celular,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	}

	editClienteCelularDisable(){
		this.editarCelular=false;
	}

	saveClienteCelular(){
		let ClienteDataModel:Clientes=this.formEditarCelular.value;
		ClienteDataModel.id=this.dataEditarClientes.id;
		this.dataEditarClientes.celular=ClienteDataModel.celular;
		this.servicioCliente.actulizarClientes(ClienteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarCelular=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editClienteCiudadEnable(){
		this.editarCiudad=true;
		this.formEditarCiudad=this.fb.group({
			ciudad:[this.dataEditarClientes.ciudad,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	}

	editClienteCiudadDisable(){
		this.editarCiudad=false;
	}

	saveClienteCiudad(){
		let ClienteDataModel:Clientes=this.formEditarCiudad.value;
		ClienteDataModel.id=this.dataEditarClientes.id;
		this.dataEditarClientes.ciudad=ClienteDataModel.ciudad;
		this.servicioCliente.actulizarClientes(ClienteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarCiudad=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editClienteDireccionEnable(){
		this.editarDireccion=true;
		this.formEditarDireccion=this.fb.group({
			direccion:[this.dataEditarClientes.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
		});
	}

	editClienteDireccionDisable(){
		this.editarDireccion=false;
	}

	saveClienteDireccion(){
		let ClienteDataModel:Clientes=this.formEditarDireccion.value;
		ClienteDataModel.id=this.dataEditarClientes.id;
		this.dataEditarClientes.direccion=ClienteDataModel.direccion;
		this.servicioCliente.actulizarClientes(ClienteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarDireccion=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editClienteCedulaEnable(){
		this.editarCedula=true;
		if(this.dataEditarClientes.tipo==1){
			this.formEditarCedula=this.fb.group({
				tipo:[1,Validators.compose([Validators.required])],
				cedula: [this.dataEditarClientes.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
			});
		}else{
			this.formEditarCedula=this.fb.group({
				tipo:[2,Validators.compose([Validators.required])],
				cedula: [this.dataEditarClientes.cedula,Validators.compose([Validators.required,Validators.minLength(5),Validators.maxLength(50)])],
			});
		}
	}

	editClienteCedulaDisable(){
		this.editarCedula=false;
	}

	saveClienteCedula(){
		let ClienteDataModel:Clientes=this.formEditarCedula.value;
		ClienteDataModel.id=this.dataEditarClientes.id;
		this.dataEditarClientes.cedula=ClienteDataModel.cedula;
		this.dataEditarClientes.tipo=ClienteDataModel.tipo;
		this.servicioCliente.actulizarClientes(ClienteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarCedula=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editClienteMailEnable(){
		this.editarMail=true;
		this.formEditarMail=this.fb.group({
			mail:[this.dataEditarClientes.mail,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150),CustomValidators.email])],
		});
	}

	editClienteMailDisable(){
		this.editarMail=false;
	}

	saveClienteMail(){
		let ClienteDataModel:Clientes=this.formEditarMail.value;
		ClienteDataModel.id=this.dataEditarClientes.id;
		this.dataEditarClientes.mail=ClienteDataModel.mail;
		this.servicioCliente.actulizarClientes(ClienteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarMail=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1.5';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "70%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	tipo(data:any){
		if(Number(data)===1){
			this.formEditarCedula=this.fb.group({
				tipo:[1,Validators.compose([Validators.required])],
				cedula: [this.dataEditarClientes.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
			});
		}else{
			this.formEditarCedula=this.fb.group({
				tipo:[2,Validators.compose([Validators.required])],
				cedula: [this.dataEditarClientes.cedula,Validators.compose([Validators.required,Validators.minLength(5),Validators.maxLength(50)])],
			});
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};