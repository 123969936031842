import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Marcas } from '../../modelo-marca/marcas.model';
import { MarcasServices } from '../../modelo-marca/servicios-marcas.services';
import { Dispositivos } from '../../../reparar-dispositivos/modelo-dispositivo/dispositivos.model';
import { DispositivosServices } from '../../../reparar-dispositivos/modelo-dispositivo/servicios-dispositivos.services';

@Component({
	selector: 'app-editarmarcamodal',
	templateUrl: './editarmarcamodal.component.html',
	styleUrls: ['./editarmarcamodal.component.scss']
})

export class EditarMarcaModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataDispositivos:any;

	public formEditarNombre: FormGroup;
	public editarNombre:boolean=false;

	public formEditarDispositivo:FormGroup;
	public editarDispositivo:boolean=false;
	public seleccionarDispositivo:number;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarMarcas: any,
		public dialogRef: MatDialogRef <EditarMarcaModalComponent>,
		private servicioMarca:MarcasServices,
		private servicioDispositivo:DispositivosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "35%");
		}else{
			this.dialogRef.updateSize("100%", "60%");
		}
	
		this.seleccionarDispositivo=this.dataEditarMarcas.dispositivo_id;
		this.servicioDispositivo.obtenerDatosDispositivos().subscribe((data:any)=>{
			this.dataDispositivos=data.dispositivos;
		});
		this.formEditarDispositivo= this.fb.group({
			dispositivo_id:[this.dataEditarMarcas.dispositivo_id,Validators.compose([Validators.required])]
		});
	
		this.formEditarNombre= this.fb.group({
			nombre:[this.dataEditarMarcas.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	
	}

	editMarcaDispositivoEnable(){
		this.editarDispositivo=true;
		this.seleccionarDispositivo=this.dataEditarMarcas.dispositivo_id;
		this.formEditarDispositivo=this.fb.group({
			dispositivo_id:[this.dataEditarMarcas.dispositivo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editMarcaDispositivoDisable(){
		this.seleccionarDispositivo=this.dataEditarMarcas.dispositivo_id;
		this.editarDispositivo=false;
	}

	saveMarcaDispositivo(){
		let MarcaDataModel:Marcas=this.formEditarDispositivo.value;
		MarcaDataModel.id=this.dataEditarMarcas.id;
		this.dataEditarMarcas.dispositivo_id=MarcaDataModel.dispositivo_id;
		this.servicioMarca.actulizarMarcas(MarcaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarDispositivo=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editMarcaNombreEnable(){
		this.editarNombre=true;
		this.formEditarNombre=this.fb.group({
			nombre:[this.dataEditarMarcas.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	}

	editMarcaNombreDisable(){
		this.editarNombre=false;
	}

	saveMarcaNombre(){
		let MarcaDataModel:Marcas=this.formEditarNombre.value;
		MarcaDataModel.id=this.dataEditarMarcas.id;
		this.dataEditarMarcas.nombre=MarcaDataModel.nombre;
		this.servicioMarca.actulizarMarcas(MarcaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarNombre=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "35%");
		}else{
			this.dialogRef.updateSize("100%", "60%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};