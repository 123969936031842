import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Problemas} from './problemas.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class ProblemasServices {

	constructor(private http: HttpClient) { }

	obtenerDatosProblemas():Observable<Problemas[]>{
		return this.http.get<Problemas[]>(Globals.BASE_URL_API_REST+'problemas.json');
	}

	guardarProblemas(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'problemas.json',data);
	}

	eliminarProblemas(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'problemas/'+id+'.json');
	}

	actulizarProblemas(data:Problemas){
		 return this.http.patch(Globals.BASE_URL_API_REST+'problemas/'+data.id+'.json',data);
	}

	verProblemas(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'problemas/'+id+'.json');
	}

	verProblemasRecepcion(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listadeproblemas.json',data);
	}

	verProblemasRecepcionNoRevizados(data:any){
		return this.http.put(Globals.BASE_URL_API_REST+'listadeproblemas.json',data);
	}
	   
}