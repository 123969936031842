import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Marcas} from './marcas.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class MarcasServices {

	constructor(private http: HttpClient) { }

	obtenerDatosMarcas():Observable<Marcas[]>{
		return this.http.get<Marcas[]>(Globals.BASE_URL_API_REST+'marcas.json');
	}

	guardarMarcas(data:Marcas){
		return this.http.post(Globals.BASE_URL_API_REST+'marcas.json',data);
	}

	eliminarMarcas(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'marcas/'+id+'.json');
	}

	actulizarMarcas(data:Marcas){
		 return this.http.patch(Globals.BASE_URL_API_REST+'marcas/'+data.id+'.json',data);
	}

	verMarcas(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'marcas/'+id+'.json');
	}

	verMarcasPorDispositivos(dataSend:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listamarcasfiltrar.json',dataSend);
   	}

}