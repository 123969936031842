import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Dispositivos } from '../../modelo-dispositivo/dispositivos.model';
import { DispositivosServices } from '../../modelo-dispositivo/servicios-dispositivos.services';

@Component({
	selector: 'app-editardispositivomodal',
	templateUrl: './editardispositivomodal.component.html',
	styleUrls: ['./editardispositivomodal.component.scss']
})

export class EditarDispositivoModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';

	public formEditarNombre: FormGroup;
	public editarNombre:boolean=false;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarDispositivos: any,
		public dialogRef: MatDialogRef <EditarDispositivoModalComponent>,
		private servicioDispositivo:DispositivosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "35%");
		}else{
			this.dialogRef.updateSize("100%", "50%");
		}
	
		this.formEditarNombre= this.fb.group({
			nombre:[this.dataEditarDispositivos.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	
	}

	editDispositivoNombreEnable(){
		this.editarNombre=true;
		this.formEditarNombre=this.fb.group({
			nombre:[this.dataEditarDispositivos.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	}

	editDispositivoNombreDisable(){
		this.editarNombre=false;
	}

	saveDispositivoNombre(){
		let DispositivoDataModel:Dispositivos=this.formEditarNombre.value;
		DispositivoDataModel.id=this.dataEditarDispositivos.id;
		this.dataEditarDispositivos.nombre=DispositivoDataModel.nombre;
		this.servicioDispositivo.actulizarDispositivos(DispositivoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarNombre=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "35%");
		}else{
			this.dialogRef.updateSize("100%", "50%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};