import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Asignaciones} from './asignaciones.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class AsignacionesServices {

	constructor(private http: HttpClient) { }

	obtenerDatosAsignaciones():Observable<Asignaciones[]>{
		return this.http.get<Asignaciones[]>(Globals.BASE_URL_API_REST+'asignaciones.json');
	}

	guardarAsignaciones(data:Asignaciones){
		return this.http.post(Globals.BASE_URL_API_REST+'asignaciones.json',data);
	}

	eliminarAsignaciones(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'asignaciones/'+id+'.json');
	}

	actulizarAsignaciones(data:Asignaciones){
		 return this.http.patch(Globals.BASE_URL_API_REST+'asignaciones/'+data.id+'.json',data);
	}

	verAsignaciones(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'asignaciones/'+id+'.json');
	}
	
	verAsignacionesRecepcion(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listadeasignaciones.json',data);
    }
	
	verAsignacionesRecepcionPorTecnicos(data:any){
		return this.http.put(Globals.BASE_URL_API_REST+'listadeasignaciones.json',data);
	}
	
	verAsignacionParaRevision(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listapararevisiones.json',data);
	}

}