import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Recepciones } from '../../modelo-recepcione/recepciones.model';
import { RecepcionesServices } from '../../modelo-recepcione/servicios-recepciones.services';
import { Equipoclientes } from '../../../reparar-equipoclientes/modelo-equipocliente/equipoclientes.model';
import { EquipoclientesServices } from '../../../reparar-equipoclientes/modelo-equipocliente/servicios-equipoclientes.services';
import { Recepcionistas } from '../../../reparar-recepcionistas/modelo-recepcionista/recepcionistas.model';
import { RecepcionistasServices } from '../../../reparar-recepcionistas/modelo-recepcionista/servicios-recepcionistas.services';

@Component({
	selector: 'app-editarrecepcionemodal',
	templateUrl: './editarrecepcionemodal.component.html',
	styleUrls: ['./editarrecepcionemodal.component.scss']
})

export class EditarRecepcioneModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataEquipoclientes:any;
	public dataRecepcionistas:any;

	public formEditarEquipocliente:FormGroup;
	public editarEquipocliente:boolean=false;
	public seleccionarEquipocliente:number;

	public formEditarRecepcionista:FormGroup;
	public editarRecepcionista:boolean=false;
	public seleccionarRecepcionista:number;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarRecepciones: any,
		public dialogRef: MatDialogRef <EditarRecepcioneModalComponent>,
		private servicioRecepcione:RecepcionesServices,
		private servicioEquipocliente:EquipoclientesServices,
		private servicioRecepcionista:RecepcionistasServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.seleccionarEquipocliente=this.dataEditarRecepciones.equipocliente_id;
		this.servicioEquipocliente.obtenerDatosEquipoclientes().subscribe((data:any)=>{
			this.dataEquipoclientes=data.equipoclientes;
		});
		this.formEditarEquipocliente= this.fb.group({
			equipocliente_id:[this.dataEditarRecepciones.equipocliente_id,Validators.compose([Validators.required])]
		});
	
		this.seleccionarRecepcionista=this.dataEditarRecepciones.recepcionista_id;
		this.servicioRecepcionista.obtenerDatosRecepcionistas().subscribe((data:any)=>{
			this.dataRecepcionistas=data.recepcionistas;
		});
		this.formEditarRecepcionista= this.fb.group({
			recepcionista_id:[this.dataEditarRecepciones.recepcionista_id,Validators.compose([Validators.required])]
		});
	
	}

	editRecepcioneEquipoclienteEnable(){
		this.editarEquipocliente=true;
		this.seleccionarEquipocliente=this.dataEditarRecepciones.equipocliente_id;
		this.formEditarEquipocliente=this.fb.group({
			equipocliente_id:[this.dataEditarRecepciones.equipocliente_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editRecepcioneEquipoclienteDisable(){
		this.seleccionarEquipocliente=this.dataEditarRecepciones.equipocliente_id;
		this.editarEquipocliente=false;
	}

	saveRecepcioneEquipocliente(){
		let RecepcioneDataModel:Recepciones=this.formEditarEquipocliente.value;
		RecepcioneDataModel.id=this.dataEditarRecepciones.id;
		this.dataEditarRecepciones.equipocliente_id=RecepcioneDataModel.equipocliente_id;
		this.servicioRecepcione.actulizarRecepciones(RecepcioneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarEquipocliente=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRecepcioneRecepcionistaEnable(){
		this.editarRecepcionista=true;
		this.seleccionarRecepcionista=this.dataEditarRecepciones.recepcionista_id;
		this.formEditarRecepcionista=this.fb.group({
			recepcionista_id:[this.dataEditarRecepciones.recepcionista_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editRecepcioneRecepcionistaDisable(){
		this.seleccionarRecepcionista=this.dataEditarRecepciones.recepcionista_id;
		this.editarRecepcionista=false;
	}

	saveRecepcioneRecepcionista(){
		let RecepcioneDataModel:Recepciones=this.formEditarRecepcionista.value;
		RecepcioneDataModel.id=this.dataEditarRecepciones.id;
		this.dataEditarRecepciones.recepcionista_id=RecepcioneDataModel.recepcionista_id;
		this.servicioRecepcione.actulizarRecepciones(RecepcioneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarRecepcionista=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};