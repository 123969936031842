import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Tecnicos } from '../../modelo-tecnico/tecnicos.model';
import { TecnicosServices } from '../../modelo-tecnico/servicios-tecnicos.services';
import { customValidatorMondogo } from 'app/appmantenimiento/custom-validator/customValidator.services';

@Component({
	selector: 'app-insertartecnicomodal',
	templateUrl: './insertartecnicomodal.component.html',
	styleUrls: ['./insertartecnicomodal.component.scss']
})

export class InsertarTecnicoModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public formInsertarTecnico: FormGroup;

	constructor(private fb: FormBuilder,
		public dialogRef: MatDialogRef <InsertarTecnicoModalComponent>,
		private validatciones:customValidatorMondogo,
		private servicioTecnico:TecnicosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.formInsertarTecnico = this.fb.group({
			cedula: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10),this.validatciones.digitoVerificador])],
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
			apellido:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
			telefono:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
			celular:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
			ciudad:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(20)])],
			direccion:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
			mail:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150),CustomValidators.email])],
			estado:[1,null],
			revisiones:[null,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}


	saveTecnico(){
		let tecnicoDataModel:Tecnicos=this.formInsertarTecnico.value;
		this.servicioTecnico.guardarTecnicos(tecnicoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.dialogRef.close(1);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}


	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};