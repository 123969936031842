import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Repuestos} from './repuestos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class RepuestosServices {

	constructor(private http: HttpClient) { }

	obtenerDatosRepuestos():Observable<Repuestos[]>{
		return this.http.get<Repuestos[]>(Globals.BASE_URL_API_REST+'repuestos.json');
	}

	guardarRepuestos(data:Repuestos){
		return this.http.post(Globals.BASE_URL_API_REST+'repuestos.json',data);
	}

	eliminarRepuestos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'repuestos/'+id+'.json');
	}

	actulizarRepuestos(data:Repuestos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'repuestos/'+data.id+'.json',data);
	}

	verRepuestos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'repuestos/'+id+'.json');
	}
}