import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { HorizontalMenuItems } from '../../shared/menu-items/horizontal-menu-items';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TranslateService } from 'ng2-translate/ng2-translate';
import PerfectScrollbar from 'perfect-scrollbar';
import { PerfectScrollbarConfigInterface,
PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

import {localStorageServices} from '../../appauth/auth/localStorageServices';
import { AuthServices } from '../../appauth/auth/auth.services';
import { SideBarMenuServices } from '../../appauth/sidebarmenu/sidebarmenu.services';
import {Globals} from '../../global/global.model';


@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html'
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  //datos usuario
  dataUser:any;
  dataMenus=[];
  public imgUrlProfile:string;


  private _router: Subscription;

  today: number = Date.now();
  url: string;
  showSettings = false;
  dark: boolean;
  boxed: boolean;
  collapseSidebar: boolean;
  compactSidebar: boolean;
  sidebarBg: boolean = true;
  currentLang = 'en';
  layoutDir = 'ltr';
  
  menuLayout           : any = 'vertical-menu';
  selectedSidebarImage : any = 'bg-2';
  selectedSidebarColor : any = 'sidebar-blue';
  selectedHeaderColor  : any = 'header-default';
  collapsedClass       : any = 'side-panel-opened';

  @ViewChild('sidemenu') sidemenu;
  public config: PerfectScrollbarConfigInterface = {};

  constructor(private router: Router, 
              public menuItems: MenuItems, 
              public horizontalMenuItems : HorizontalMenuItems, 
              public translate: TranslateService,
              public localdata:localStorageServices,
              public authServices:AuthServices,
              public menubar:SideBarMenuServices ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    this.dataUser=localdata.getValueFromLocalStorage().datos;
  }

  ngOnInit(): void {
   
    if(this.dataUser.fotousers[0] !== undefined)
      this.imgUrlProfile=Globals.BASE_URL_API_REST+this.dataUser.fotousers[0].dir+this.dataUser.fotousers[0].filename;

    this.menubar.getmenu().subscribe((dataMenu:any)=>{
     
      dataMenu.menus.forEach(function(currentValue,index,arr) {
        delete currentValue['id']; 
        if(currentValue.children){
          delete currentValue['id'];
        }
      });
      this.dataMenus=dataMenu.menus;
      console.log(this.dataMenus);
      
      
    });
    
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container ');
    
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac() && !this.compactSidebar && this.layoutDir != 'rtl') {
      const ps = new PerfectScrollbar(elemSidebar, {
                              wheelSpeed: 2,
                              suppressScrollX: true
                            });
    }

    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.url = event.url;
      if (this.isOver()) {
        this.sidemenu.close();
      }

      if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac() && !this.compactSidebar && this.layoutDir != 'rtl') {
        // Ps.update(elemContent);
      }
    });
  }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container ');
    setTimeout(() => {
      if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac() && !this.compactSidebar && this.layoutDir != 'rtl') {
        const ps = new PerfectScrollbar(elemSidebar, {
                              wheelSpeed: 2,
                              suppressScrollX: true
                            });
      }
    }, 350);
  }

  ngOnDestroy() {
    this._router.unsubscribe();
  }

  isOver(): boolean {
    if (this.url === '/apps/messages' || this.url === '/apps/calendar' || this.url === '/apps/media' || this.url === '/maps/leaflet') {
      return true;
    } else {
      return window.matchMedia(`(max-width: 960px)`).matches;
    }
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  menuMouseOver(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
      this.sidemenu.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
      this.sidemenu.mode = 'side';
    }
  }
  
  menuToggleFunc()
  {
    this.sidemenu.toggle();
    
    if(this.collapsedClass == 'side-panel-opened')
    {
        this.collapsedClass = 'side-panel-closed';
    }
    else
    {
        this.collapsedClass= 'side-panel-opened';
    }
  }

  changeMenuLayout(value)
  {
    console.log(value)
    if(value)
    {
      this.menuLayout = 'top-menu';
    }
    else
    {
      this.menuLayout = 'vertical-menu';
    }
  }
  
  onSelectSidebarImage(selectedClass, event)
  {
    this.selectedSidebarImage = selectedClass;
  }
  
  onSelectedSidebarColor(selectedClass)
  {
    this.selectedSidebarColor = selectedClass;
  }
  
  onSelectedHeaderColor(selectedClass)
  {
    this.selectedHeaderColor = selectedClass;
  }

  isBgActive(value)
  {
    if(value == this.selectedSidebarImage)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  isSidebarActive(value)
  {
    if(value == this.selectedSidebarColor)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  isHeaderActive(value)
  {
    if(value == this.selectedHeaderColor)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  addMenuItem(): void {
    this.menuItems.add({
      state: 'menu',
      name: 'MENU',
      type: 'sub',
      icon: 'trending_flat',
      children: [
        {state: 'menu', name: 'MENU'},
        {state: 'timelmenuine', name: 'MENU'}
      ]
    });
  }

  //Evento logout

  cerrarSession(){
    console.log("cerrar session");
    
    this.authServices.logout().subscribe( (data:any) => {
      if(data.message.estado){
        this.localdata.removeValueFromLocalStorage();
        this.router.navigate(['authentication/login']);
      }
    });
  }

  profile(){
    this.router.navigate(['user-pages/userprofile']);
  }

  imagePhoto(){
    this.router.navigate(['user-pages/photo']);
  }


  home(){
    this.router.navigate(['home']);
  }

}
