import { Routes } from '@angular/router';
import {AuthGuard} from '../../appauth/auth/auth.guard';
import { ProblemaListaComponent } from './view-problema/problemavista.component';
export const ProblemasRoutes: Routes = [{
	path: '',
	redirectTo: 'problema-lista',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'problema-lista',
		component: ProblemaListaComponent,
		canActivate: [AuthGuard]
	}]
}];
