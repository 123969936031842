import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import {AuthGuard} from './appauth/auth/auth.guard';
import {AuthLoginGuard} from './appauth/auth/auth.login.guard';

export const AppRoutes: Routes = [{
  path: '',
  redirectTo: 'authentication',
  pathMatch: 'full',
}, {
  path: '',
  component: AdminLayoutComponent,
  children: [{
    path: 'home',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard]
  },{
    path: 'group-pages',
    loadChildren: './appauth/group-pages/groups.module#GroupsModule',
    canActivate: [AuthGuard]
  },{
    path: 'user-pages',
    loadChildren: './appauth/user-pages/users.module#UsersModule',
    canActivate: [AuthGuard]
  },{
    path: 'reparar-dispositivos',
    loadChildren: './appmantenimiento/reparar-dispositivos/dispositivos.module#DispositivosModule',
    canActivate: [AuthGuard]
  },{
    path: 'reparar-marcas',
    loadChildren: './appmantenimiento/reparar-marcas/marcas.module#MarcasModule',
    canActivate: [AuthGuard]
  },{
    path: 'reparar-modelos',
    loadChildren: './appmantenimiento/reparar-modelos/modelos.module#ModelosModule',
    canActivate: [AuthGuard]
  },{
    path: 'reparar-recepcionistas',
    loadChildren: './appmantenimiento/reparar-recepcionistas/recepcionistas.module#RecepcionistasModule',
    canActivate: [AuthGuard]
  },{
    path: 'reparar-tecnicos',
    loadChildren: './appmantenimiento/reparar-tecnicos/tecnicos.module#TecnicosModule',
    canActivate: [AuthGuard]
  },{
    path: 'reparar-clientes',
    loadChildren: './appmantenimiento/reparar-clientes/clientes.module#ClientesModule',
    canActivate: [AuthGuard]
  },{
    path: 'reparar-recepciones',
    loadChildren: './appmantenimiento/reparar-recepciones/recepciones.module#RecepcionesModule',
    canActivate: [AuthGuard]
  },{
    path: 'reparar-asignaciones',
    loadChildren: './appmantenimiento/reparar-asignaciones/asignaciones.module#AsignacionesModule',
    canActivate: [AuthGuard]
  },{
    path: 'reparar-reportes',
    loadChildren: './appmantenimiento/reparar-reportes/reportes.module#ReportesMantenimientoModule',
    canActivate: [AuthGuard]
  }]
}, {
  path: '',
  component: AuthLayoutComponent,
  canActivate: [AuthLoginGuard],
  children: [{
    path: 'authentication',
    loadChildren: './session/session.module#SessionModule'
  },{
    path: 'error',
    loadChildren: './error/error.module#ErrorModule'
  }]
}, {
  path: '**',
  redirectTo: 'session/404'
}];
