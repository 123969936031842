import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Dispositivos} from './dispositivos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class DispositivosServices {

	constructor(private http: HttpClient) { }

	obtenerDatosDispositivos():Observable<Dispositivos[]>{
		return this.http.get<Dispositivos[]>(Globals.BASE_URL_API_REST+'dispositivos.json');
	}

	guardarDispositivos(data:Dispositivos){
		return this.http.post(Globals.BASE_URL_API_REST+'dispositivos.json',data);
	}

	eliminarDispositivos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'dispositivos/'+id+'.json');
	}

	actulizarDispositivos(data:Dispositivos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'dispositivos/'+data.id+'.json',data);
	}

	verDispositivos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'dispositivos/'+id+'.json');
	}
}