import { Component, OnInit,ViewEncapsulation, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices} from '../../../appauth/notification/notificatio.services';
import { Repuestoservicios } from '../modelo-repuestoservicio/repuestoservicios.model';
import { RepuestoserviciosServices } from '../modelo-repuestoservicio/servicios-repuestoservicios.services';
import { InsertarRepuestoservicioModalComponent } from '../modales-repuestoservicio/insertar/insertarrepuestoserviciomodal.component';
import { EditarRepuestoservicioModalComponent } from '../modales-repuestoservicio/editar/editarrepuestoserviciomodal.component';
import { Modelos } from 'app/appmantenimiento/reparar-modelos/modelo-modelo/modelos.model';
import * as moment from 'moment';

@Component({
	selector: 'ms-repuestoserviciovista',
	templateUrl:'./repuestoserviciovista.component.html',
	styleUrls: ['./repuestoserviciovista.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class RepuestoservicioListaComponent implements OnInit {
	@Input() Modelo:Modelos;
	@Input() srSelccionado:any;
	@Output() valueChange = new EventEmitter();
	
	rows = [];
	temp = [];

	config: MatDialogConfig = {
		disableClose: false,
		width: '50%',
		height: '60%',
		position: {
			top: '15%',
			bottom: '',
			left: '',
			right: ''
		},
		data: { datasend: null }
	};

	
	constructor(private serviciosRepuestoservicios:RepuestoserviciosServices,
		public dialog: MatDialog,
		public mensajes:NotificationServices,
	){}

	ngOnInit(){
		if(this.Modelo!=undefined){
			this.listaRepuestoservicios();
		}
	}

	listaRepuestoservicios(){
		let promise = new Promise((resolve, reject) => {
			this.serviciosRepuestoservicios.verRepuestoserviciosModelo(this.Modelo).subscribe((data:any)=>{

				data.repuestoservicios.forEach((element,index )=> {
					data.repuestoservicios[index].checked=false;
					data.repuestoservicios[index].valor=0;
					if(element.tipo==2){
						let fecha1 = moment('2000-01-01');
						let fecha2 = moment(element.tiempo);
						data.repuestoservicios[index].valor=fecha2.diff(fecha1, 'days');

					}else if(element.tipo==3){
						let fecha1 = moment('2000-01-01');
						let fecha2 = moment(element.tiempo);
						data.repuestoservicios[index].valor=fecha2.diff(fecha1, 'days')/7;
					}
					this.srSelccionado.forEach((obd,i) => {
						if(element.id==obd.id){
							data.repuestoservicios[index].checked=true;
						}
					},this);
				},this);
				this.temp = [...data.repuestoservicios];
				this.rows=data.repuestoservicios;
				resolve(true);
			});
		});
        return promise;
	}

	openRepuestoservicio() {
		this.config.data=this.Modelo;
		let dialogRef = this.dialog.open(InsertarRepuestoservicioModalComponent, this.config);
		dialogRef.afterClosed().subscribe((resultd:any) => {
			if(resultd){
				if(resultd.op==1){
					this.listaRepuestoservicios().then(
					result=>{
						if(result)
							{
								this.rows.forEach(element => {
									if(element.id==resultd.datos.id){
										this.srSelccionado.push(element);
										this.valueChange.emit(this.srSelccionado); 
										this.listaRepuestoservicios();
									}	
								});	
								
							}
						},
						reason=>{
							console.log(reason);
						}
					);
				}
			}
		});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter((d)=> {
			return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}

	deleteRepuestoservicio(dataRepuestoservicio:any){
		let data={'val':dataRepuestoservicio.nombre,'msg':'Eliminar Repuestoservicio'};
		this.mensajes.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosRepuestoservicios.eliminarRepuestoservicios(dataRepuestoservicio.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.mensajes.setNotification(smsdata).then((datas)=>{
							if(datas){
								let esta:boolean=false;
								let index:number=0;
								this.srSelccionado.forEach((element,i) => {
									if(element.id==smsdata.datos.id){
										esta=true;
										index=i;
									}	
								});
								if(esta){
									this.srSelccionado.splice( index, 1 );
									this.valueChange.emit(this.srSelccionado); 
								}
								this.listaRepuestoservicios();
							}
						});
					}else{
						this.mensajes.setNotification(smsdata);
					}
				});
			}
		});
	}

	editRepuestoservicio(data:any) {
		this.config.data=data;
		let dialogRef = this.dialog.open(EditarRepuestoservicioModalComponent, this.config);
		dialogRef.afterClosed().subscribe((resultd:any) => {
			if(resultd){
				if(resultd.op==1){
					this.listaRepuestoservicios().then(
					result=>{
						if(result)
							{
								if(this.srSelccionado.length>0){
									let elementoActulizado:any;
									this.rows.forEach(element => {
										if(element.id==resultd.datos.id){
											elementoActulizado=element;
										}	
									});	
									let indiceActulizar=0;
									this.srSelccionado.forEach((element,i) => {
										if(element.id==resultd.datos.id){
											indiceActulizar=i;
										}	
									});	
									this.srSelccionado[indiceActulizar]=elementoActulizado;
									this.valueChange.emit(this.srSelccionado); 
								}
							}
						},
						reason=>{
							console.log(reason);
						}
					);
				}
			}
		});
	}

	selccion(data:any,event:any){
		
		if(event.checked ){
			let esta:boolean=false;
			this.srSelccionado.forEach(element => {
				if(element.id==data.id)
					esta=true;
					
			});
			if(!esta)
				this.srSelccionado.push(data);
		}else{
			var i = this.srSelccionado.indexOf( data );
			this.srSelccionado.splice( i, 1 );
		}
		this.valueChange.emit(this.srSelccionado); 
		
		
	}

}