import { Component, OnInit,ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices} from '../../../appauth/notification/notificatio.services';
import { Clientes } from '../modelo-cliente/clientes.model';
import { ClientesServices } from '../modelo-cliente/servicios-clientes.services';
import { InsertarClienteModalComponent } from '../modales-cliente/insertar/insertarclientemodal.component';
import { EditarClienteModalComponent } from '../modales-cliente/editar/editarclientemodal.component';
import { Globals } from 'app/global/global.model';
import { FotoClientesComponent } from 'app/appmantenimiento/reparar-fotoclientes/foto/fotocliente.compente';

@Component({
	selector: 'ms-clientevista',
	templateUrl:'./clientevista.component.html',
	styleUrls: ['./clientevista.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ClienteListaComponent implements OnInit {
	rows = [];
	temp = [];
	
	public imgUrlClientes:string;
	iconItems = [{
		text: 'Nuevo',
		icon: 'add'
	},{
		text: 'Refrescar',
		icon: 'refresh'
	}];

	config: MatDialogConfig = {
		disableClose: false,
		width: '50%',
		height: '60%',
		position: {
			top: '5%',
			bottom: '',
			left: '',
			right: ''
		},
		data: { datasend: null }
	};

	configFoto: MatDialogConfig = {
		disableClose: false,
		width: '35%',
		height: '30%',
		position: {
			top: '5%',
			bottom: '',
			left: '',
			right: ''
		},
		data: { datasend: null }
	};

	select(text: string) {
		if(text=='Refrescar'){
			this.listaClientes();
		}else if(text=='Nuevo'){
			this.openCliente();
		}
	}

	constructor(private serviciosClientes:ClientesServices,
		public dialog: MatDialog,
		public mensajes:NotificationServices,
	){}

	ngOnInit(){
		this.imgUrlClientes=Globals.BASE_URL_API_REST;
		this.listaClientes();
	}

	listaClientes(){
		this.serviciosClientes.obtenerDatosClientes().subscribe((data:any)=>{
			this.temp = [...data.clientes];
			this.rows=data.clientes;
		});
	}

	openCliente() {
		let dialogRef = this.dialog.open(InsertarClienteModalComponent, this.config);
		dialogRef.afterClosed().subscribe(result => {
			if(result==1)
				this.listaClientes();
		});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter((d)=> {
			return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}

	deleteCliente(dataCliente:any){
		let data={'val':dataCliente.nombre,'msg':'Eliminar Cliente'};
		this.mensajes.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosClientes.eliminarClientes(dataCliente.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.mensajes.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.listaClientes();
							}
						});
					}else{
						this.mensajes.setNotification(smsdata);
					}
				});
			}
		});
	}

	editCliente(data:any) {
		this.config.data=data;
		let dialogRef = this.dialog.open(EditarClienteModalComponent, this.config);
		dialogRef.afterClosed().subscribe(result => {
			if(result==1)
				this.listaClientes();
		});
	}

	editFotoClientes(data:any){
		this.configFoto.data=data;
		let dialogRef = this.dialog.open(FotoClientesComponent, this.configFoto);
		dialogRef.afterClosed().subscribe(result => {
			if(result==1)
				this.listaClientes();
		});
	}

}