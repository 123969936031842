import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {MatInputModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatListModule,
		MatGridListModule,
		MatProgressBarModule,
		MatMenuModule,
		MatButtonToggleModule,
		MatTabsModule,
		MatSelectModule,
		MatTableModule,
		MatRadioModule,
		MatToolbarModule, 
		MatCheckboxModule} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EditarProblemaModalComponent } from './editar/editarproblemamodal.component';
import { InsertarProblemaModalComponent } from './insertar/insertarproblemamodal.component';
import { RepuestoserviciosModule } from 'app/appmantenimiento/reparar-repuestoservicios/repuestoservicios.module';
@NgModule({
	declarations: [
		EditarProblemaModalComponent,
		InsertarProblemaModalComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatListModule,
		ReactiveFormsModule,
		MatProgressBarModule,
		MatMenuModule,
		MatToolbarModule,
		FlexLayoutModule,
		MatButtonToggleModule,
		MatCheckboxModule,
		MatGridListModule,
		MatSelectModule,
		MatRadioModule,
		MatTabsModule,
		MatTableModule,
		NgxDatatableModule,
		RepuestoserviciosModule

	],
	entryComponents: [
		EditarProblemaModalComponent,
		InsertarProblemaModalComponent,
	]
})
export class ProblemasModalModule {}
