import { Component, OnInit } from '@angular/core';
import { DispositivosServices } from 'app/appmantenimiento/reparar-dispositivos/modelo-dispositivo/servicios-dispositivos.services';
import { Dispositivos } from 'app/appmantenimiento/reparar-dispositivos/modelo-dispositivo/dispositivos.model';
import { Marcas } from 'app/appmantenimiento/reparar-marcas/modelo-marca/marcas.model';
import { MarcasServices } from 'app/appmantenimiento/reparar-marcas/modelo-marca/servicios-marcas.services';
import { ModelosServices } from '../../modelo-modelo/servicios-modelos.services';
import { Modelos } from '../../modelo-modelo/modelos.model';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-registrarecepcion',
  templateUrl: './registrarecepcion.component.html',
  styleUrls: ['./registrarecepcion.component.scss']
})
export class RegistrarecepcionComponent implements OnInit {

  rows = [];
	temp = [];
	rowms = [];
  tempm = [];
  rowmodels = [];
  tempmodel = [];
  //para los textos de busqueda
  valorBusquedaDispositivo:string="";
  valorBusquedaMarca:string="";
  valorBusquedaModelo:string="";
  //para almacenar temporalmente los selecionado
  dataDispositivoTable:Dispositivos;
  dataMarcaInsert:Marcas;
  dataModeloSelect:Modelos;
  //Ver la ruta de los seleccionados
  pathdispositivo:string="";
  pathmaraca:string="";
  pathmodel:string="";
  pathfull:string;
  
  // para habilitar las siguientes pasos

  verDispositivo:boolean=true;
	verMarca:boolean=false;
	verInsertar:boolean=false;
  
  constructor(private serviciosDispositivos:DispositivosServices,
              private servicioMarca:MarcasServices,
              public dialogRef: MatDialogRef <RegistrarecepcionComponent>,
              private servicioModelo:ModelosServices,
  ){ }

  ngOnInit() {
    this.listaDispositivos();
  }

  // dispocitivos

  listaDispositivos(){
		this.serviciosDispositivos.obtenerDatosDispositivos().subscribe((data:any)=>{
			this.temp = [...data.dispositivos];
			this.rows=data.dispositivos;
		});
  }
  
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.valorBusquedaDispositivo=val;
		const temp = this.temp.filter((d)=> {
			return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
  }
  
  verDispositivoInser(data:any){
    if(Number(data.op)===1){
      console.log(data);
      this.dataDispositivoTable=data.data;
      this.pathdispositivo=this.dataDispositivoTable.nombre;
      this.pathfull=this.pathdispositivo;
      this.selectDispocitivo(this.dataDispositivoTable,0);
      this.listaDispositivos();
    }
  }

  selectDispocitivo(data:any,estado:number){
		this.pathdispositivo=data.nombre;
		this.pathfull=this.pathdispositivo;
    this.dataDispositivoTable=data;
    this.verDispositivo=false;
		this.verMarca=true;
    this.verInsertar=false;
    if(estado==0){
      this.dataModeloSelect=undefined;
      this.dataMarcaInsert=undefined;
    }
    if(this.dataMarcaInsert!=undefined){
      this.pathmaraca=this.dataMarcaInsert.nombre;
      this.pathfull=this.pathdispositivo+'/'+this.pathmaraca;

      if(this.dataModeloSelect!=undefined){
        this.pathmodel=this.dataModeloSelect.nombre;
        this.pathfull=this.pathdispositivo+'/'+this.pathmaraca+'/'+this.pathmodel;
      }
    }
		this.servicioMarca.verMarcasPorDispositivos(data).subscribe((data:any)=>{
			this.rowms=data.marcas;
			this.tempm = [...this.rowms];
		});
  }
  
  gotoDispositivo(){
		this.verDispositivo=true;
		this.verMarca=false;
		this.verInsertar=false;
		this.rowms=[];
		this.tempm = [...this.rowms];
  }
  
  // marcas

  updateFilterMarca(event) {
    const valm = event.target.value.toLowerCase();
    this.valorBusquedaMarca=valm;
		const tempm = this.tempm.filter((d)=> {
			return d.nombre.toLowerCase().indexOf(valm) !== -1 || !valm;
		});
		this.rowms = tempm;
  }
  

  verMarcaInser(data:any){
    if(Number(data.op)===1){
      console.log(data);
      this.dataMarcaInsert=data.data;
      this.pathmaraca=this.dataMarcaInsert.nombre;
      this.pathfull=this.pathdispositivo+'/'+this.pathmaraca;
      this.selectDispocitivo(this.dataDispositivoTable,1);
      this.selectMarca(this.dataMarcaInsert,0);
    }
  }

  selectMarca(data:any,estado:number){
		this.verDispositivo=false;
		this.verMarca=false;
    this.verInsertar=true;
    if(estado==0){
      this.dataModeloSelect=undefined;
    }
		this.pathmaraca=data.nombre;
    this.pathfull=this.pathdispositivo+'/'+this.pathmaraca;
    
    if(this.dataModeloSelect!=undefined){
      this.pathmodel=this.dataModeloSelect.nombre;
      this.pathfull=this.pathdispositivo+'/'+this.pathmaraca+'/'+this.pathmodel;
    }
    this.dataMarcaInsert=data;
    this.servicioModelo.verModelosPorMarca(data).subscribe((data:any)=>{
			this.rowmodels=data.modelos;
			this.tempmodel=[...this.rowmodels];
		});
  }

  gotoMarca(){
		this.verDispositivo=false;
		this.verMarca=true;
		this.verInsertar=false;
		this.rowms=[];
    this.tempm = [...this.rowms];
		this.selectDispocitivo(this.dataDispositivoTable,1);
  }

  gotoInsert(){
		this.verDispositivo=false;
		this.verMarca=false;
		this.verInsertar=true;
		this.pathmaraca=this.dataMarcaInsert.nombre;
    this.pathfull=this.pathdispositivo+'/'+this.pathmaraca;
    if(this.dataModeloSelect!=undefined){
      this.pathmodel=this.dataModeloSelect.nombre;
      this.pathfull=this.pathdispositivo+'/'+this.pathmaraca+'/'+this.pathmodel;
    }
	}
  
  //modelos

  updateFilterModelo(event) {
    const valmodel = event.target.value.toLowerCase();
    this.valorBusquedaModelo=valmodel;
		const tempmod = this.tempmodel.filter((d)=> {
			return d.nombre.toLowerCase().indexOf(valmodel) !== -1 || !valmodel;
		});
		this.rowmodels = tempmod;
  }

  selectModelo(data:Modelos){
   
    this.dataModeloSelect=data;
    this.pathmodel=this.dataModeloSelect.nombre;
    this.pathfull=this.pathdispositivo+'/'+this.pathmaraca+'/'+this.pathmodel;
  }

  verModeloInser(data:any){
    if(Number(data.op)===1){
      this.dataModeloSelect=data.data;
      this.pathmodel=this.dataModeloSelect.nombre;
      this.pathfull=this.pathdispositivo+'/'+this.pathmaraca+'/'+this.pathmodel;
      this.selectMarca(this.dataMarcaInsert,1);
    }
  }

  closeModal(data:any){
    let dataCloseModal = {op:1,data:{dispositivo:this.dataDispositivoTable,marca:this.dataMarcaInsert,modelo:data}}
    this.dialogRef.close(dataCloseModal);
  }


}
