import { Component, OnInit, Input } from '@angular/core';
import { Globals } from 'app/global/global.model';

@Component({
  selector: 'app-infocliente',
  templateUrl: './infocliente.component.html',
  styleUrls: ['./infocliente.component.scss']
})
export class InfoclienteComponent implements OnInit {

  @Input() dataCliente:any;

  public imgUrlTecnicos:string;
  constructor() { }

  ngOnInit() {
    this.imgUrlTecnicos=Globals.BASE_URL_API_REST;
  }

}
