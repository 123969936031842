import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FotoRecepcionistasComponent } from './foto/fotorecepcionista.compente';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[FotoRecepcionistasComponent],
	entryComponents:[FotoRecepcionistasComponent],
})
export class ModalFotoRecepcionistasModule { }
