import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Equipos } from '../../modelo-equipo/equipos.model';
import { EquiposServices } from '../../modelo-equipo/servicios-equipos.services';
import { Modelos } from '../../../reparar-modelos/modelo-modelo/modelos.model';
import { ModelosServices } from '../../../reparar-modelos/modelo-modelo/servicios-modelos.services';

@Component({
	selector: 'app-editarequipomodal',
	templateUrl: './editarequipomodal.component.html',
	styleUrls: ['./editarequipomodal.component.scss']
})

export class EditarEquipoModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	
	public formEditarTiempouso: FormGroup;
	public editarTiempouso:boolean=false;


	public formEditarColor: FormGroup;
	public editarColor:boolean=false;

	public formEditarEstadoequipo: FormGroup;
	public editarEstadoequipo:boolean=false;

	public formEditarNumeroSerie: FormGroup;
	public editarNumeroSerie:boolean=false;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarEquipos: any,
		public dialogRef: MatDialogRef <EditarEquipoModalComponent>,
		private servicioEquipo:EquiposServices,
		private servicioModelo:ModelosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "30%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.formEditarTiempouso= this.fb.group({
			tiempouso:[this.dataEditarEquipos.tiempouso,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(25)])],
		});
	
		this.formEditarColor= this.fb.group({
			color:[this.dataEditarEquipos.color,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	
		this.formEditarEstadoequipo= this.fb.group({
			estadoequipo:[this.dataEditarEquipos.estadoequipo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(15)])],
		});

		this.formEditarNumeroSerie= this.fb.group({
			numeroserie:[this.dataEditarEquipos.numeroserie,Validators.compose([Validators.required,Validators.minLength(0), Validators.maxLength(100)])],
		});
	
	}

	editEquipoTiempousoEnable(){
		this.editarTiempouso=true;
		this.formEditarTiempouso=this.fb.group({
			tiempouso:[this.dataEditarEquipos.tiempouso,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(25)])],
		});
	}

	editEquipoTiempousoDisable(){
		this.editarTiempouso=false;
	}

	saveEquipoTiempouso(){
		let EquipoDataModel:Equipos=this.formEditarTiempouso.value;
		EquipoDataModel.id=this.dataEditarEquipos.id;
		this.dataEditarEquipos.tiempouso=EquipoDataModel.tiempouso;
		this.servicioEquipo.actulizarEquipos(EquipoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarTiempouso=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editEquipoNumeroSerieEnable(){
		this.editarNumeroSerie=true;
		this.formEditarNumeroSerie=this.fb.group({
			numeroserie:[this.dataEditarEquipos.numeroserie,Validators.compose([Validators.required,Validators.minLength(0), Validators.maxLength(100)])],
		});
	}

	editEquipoNumeroSerieDisable(){
		this.editarNumeroSerie=false;
	}

	saveEquipoNumeroSerie(){
		let EquipoDataModel:Equipos=this.formEditarNumeroSerie.value;
		EquipoDataModel.id=this.dataEditarEquipos.id;
		this.dataEditarEquipos.numeroserie=EquipoDataModel.numeroserie;
		this.servicioEquipo.actulizarEquipos(EquipoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarNumeroSerie=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editEquipoColorEnable(){
		this.editarColor=true;
		this.formEditarColor=this.fb.group({
			color:[this.dataEditarEquipos.color,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	}

	editEquipoColorDisable(){
		this.editarColor=false;
	}

	saveEquipoColor(){
		let EquipoDataModel:Equipos=this.formEditarColor.value;
		EquipoDataModel.id=this.dataEditarEquipos.id;
		this.dataEditarEquipos.color=EquipoDataModel.color;
		this.servicioEquipo.actulizarEquipos(EquipoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarColor=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editEquipoEstadoequipoEnable(){
		this.editarEstadoequipo=true;
		this.formEditarEstadoequipo=this.fb.group({
			estadoequipo:[this.dataEditarEquipos.estadoequipo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(15)])],
		});
	}

	editEquipoEstadoequipoDisable(){
		this.editarEstadoequipo=false;
	}

	saveEquipoEstadoequipo(){
		let EquipoDataModel:Equipos=this.formEditarEstadoequipo.value;
		EquipoDataModel.id=this.dataEditarEquipos.id;
		this.dataEditarEquipos.estadoequipo=EquipoDataModel.estadoequipo;
		this.servicioEquipo.actulizarEquipos(EquipoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarEstadoequipo=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "30%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};