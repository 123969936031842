import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {MatInputModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatListModule,
		MatGridListModule,
		MatProgressBarModule,
		MatMenuModule,
		MatButtonToggleModule,
		MatTabsModule,
		MatSelectModule,
		MatTableModule,
		MatRadioModule,
		MatToolbarModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EditarModeloModalComponent } from './editar/editarmodelomodal.component';
import { InsertarModeloModalComponent } from './insertar/insertarmodelomodal.component';
import { RegistrarecepcionComponent } from './registrarecepcion/registrarecepcion.component';
import { NuevorecepcionComponent } from './nuevorecepcion/nuevorecepcion.component';
import { NuevomarcarecepcionComponent } from './nuevomarcarecepcion/nuevomarcarecepcion.component';
import { NuevomodelorecepcionComponent } from './nuevomodelorecepcion/nuevomodelorecepcion.component';
@NgModule({
	declarations: [
		EditarModeloModalComponent,
		InsertarModeloModalComponent,
		RegistrarecepcionComponent,
		NuevorecepcionComponent,
		NuevomarcarecepcionComponent,
		NuevomodelorecepcionComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatListModule,
		ReactiveFormsModule,
		MatProgressBarModule,
		MatMenuModule,
		MatToolbarModule,
		FlexLayoutModule,
		MatButtonToggleModule,
		MatGridListModule,
		MatSelectModule,
		MatRadioModule,
		MatTabsModule,
		MatTableModule,
		NgxDatatableModule
	],
	entryComponents: [
		EditarModeloModalComponent,
		InsertarModeloModalComponent,
		RegistrarecepcionComponent
	]
})
export class ModelosModalModule {}
