import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Modelos} from './modelos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Marcas } from 'app/appmantenimiento/reparar-marcas/modelo-marca/marcas.model';


@Injectable()
export class ModelosServices {

	constructor(private http: HttpClient) { }

	obtenerDatosModelos():Observable<Modelos[]>{
		return this.http.get<Modelos[]>(Globals.BASE_URL_API_REST+'modelos.json');
	}

	guardarModelos(data:Modelos){
		return this.http.post(Globals.BASE_URL_API_REST+'modelos.json',data);
	}

	eliminarModelos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'modelos/'+id+'.json');
	}

	actulizarModelos(data:Modelos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'modelos/'+data.id+'.json',data);
	}

	verModelos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'modelos/'+id+'.json');
	}

	verModelosPorMarca(data:Marcas){
		return this.http.post(Globals.BASE_URL_API_REST+'listamodelosfiltrar.json',data);
    }

}