import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Factura } from '../../modelo-factur/factura.model';
import { FacturaServices } from '../../modelo-factur/servicios-factura.services';
import { Asignaciones } from '../../../reparar-asignaciones/modelo-asignacione/asignaciones.model';
import { AsignacionesServices } from '../../../reparar-asignaciones/modelo-asignacione/servicios-asignaciones.services';

@Component({
	selector: 'app-insertarfacturmodal',
	templateUrl: './insertarfacturmodal.component.html',
	styleUrls: ['./insertarfacturmodal.component.scss']
})

export class InsertarFacturModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataAsignaciones:any;
	public formInsertarFactur: FormGroup;

	constructor(private fb: FormBuilder,
		public dialogRef: MatDialogRef <InsertarFacturModalComponent>,
		private servicioFactur:FacturaServices,
		private servicioAsignacione:AsignacionesServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.servicioAsignacione.obtenerDatosAsignaciones().subscribe((data:any)=>{
			this.dataAsignaciones=data.asignaciones;
		});
	
		this.formInsertarFactur = this.fb.group({
			total:[null,Validators.compose([Validators.required,CustomValidators.number])],
			fecha:[null,Validators.compose([Validators.required,CustomValidators.date])],
			estado:[1,null],
			asignacione_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}


	saveFactur(){
		let facturDataModel:Factura=this.formInsertarFactur.value;
		this.servicioFactur.guardarFactura(facturDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.dialogRef.close(1);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}


	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};