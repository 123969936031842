import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Modelos } from '../../modelo-modelo/modelos.model';
import { ModelosServices } from '../../modelo-modelo/servicios-modelos.services';
import { Marcas } from '../../../reparar-marcas/modelo-marca/marcas.model';
import { MarcasServices } from '../../../reparar-marcas/modelo-marca/servicios-marcas.services';

@Component({
	selector: 'app-editarmodelomodal',
	templateUrl: './editarmodelomodal.component.html',
	styleUrls: ['./editarmodelomodal.component.scss']
})

export class EditarModeloModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataMarcas:any;

	public formEditarNombre: FormGroup;
	public editarNombre:boolean=false;

	public formEditarMarca:FormGroup;
	public editarMarca:boolean=false;
	public seleccionarMarca:number;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarModelos: any,
		public dialogRef: MatDialogRef <EditarModeloModalComponent>,
		private servicioModelo:ModelosServices,
		private servicioMarca:MarcasServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "35%");
		}else{
			this.dialogRef.updateSize("100%", "50%");
		}
	
		this.seleccionarMarca=this.dataEditarModelos.marca_id;
		this.servicioMarca.obtenerDatosMarcas().subscribe((data:any)=>{
			this.dataMarcas=data.marcas;
		});
		this.formEditarMarca= this.fb.group({
			marca_id:[this.dataEditarModelos.marca_id,Validators.compose([Validators.required])]
		});
	
		this.formEditarNombre= this.fb.group({
			nombre:[this.dataEditarModelos.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	
	}

	editModeloMarcaEnable(){
		this.editarMarca=true;
		this.seleccionarMarca=this.dataEditarModelos.marca_id;
		this.formEditarMarca=this.fb.group({
			marca_id:[this.dataEditarModelos.marca_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editModeloMarcaDisable(){
		this.seleccionarMarca=this.dataEditarModelos.marca_id;
		this.editarMarca=false;
	}

	saveModeloMarca(){
		let ModeloDataModel:Modelos=this.formEditarMarca.value;
		ModeloDataModel.id=this.dataEditarModelos.id;
		this.dataEditarModelos.marca_id=ModeloDataModel.marca_id;
		this.servicioModelo.actulizarModelos(ModeloDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarMarca=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editModeloNombreEnable(){
		this.editarNombre=true;
		this.formEditarNombre=this.fb.group({
			nombre:[this.dataEditarModelos.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
		});
	}

	editModeloNombreDisable(){
		this.editarNombre=false;
	}

	saveModeloNombre(){
		let ModeloDataModel:Modelos=this.formEditarNombre.value;
		ModeloDataModel.id=this.dataEditarModelos.id;
		this.dataEditarModelos.nombre=ModeloDataModel.nombre;
		this.servicioModelo.actulizarModelos(ModeloDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarNombre=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "35%");
		}else{
			this.dialogRef.updateSize("100%", "50%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};