import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
	selector: 'app-infoproblemas',
	templateUrl: './infoproblemas.component.html',
	styleUrls: ['./infoproblemas.component.scss']
})
export class InfoproblemasComponent implements OnInit {
	@Input() dataProblemas: any;
	minDisplay: any;
	horaDisplay: any;
	diasDisplay: any;
	semanaDisplay: any;
	totalCosto: number = 0;
	constructor() { }

	ngOnInit() {

		this.dataProblemas.forEach((element, index) => {
			this.dataProblemas[index].repuestoservicio.valor = 0;
			if (element.repuestoservicio.tipo == 2) {
				let fecha1 = moment('2000-01-01');
				let fecha2 = moment(element.repuestoservicio.tiempo);
				this.dataProblemas[index].repuestoservicio.valor = fecha2.diff(fecha1, 'days');

			} else if (element.repuestoservicio.tipo == 3) {
				let fecha1 = moment('2000-01-01');
				let fecha2 = moment(element.repuestoservicio.tiempo);
				this.dataProblemas[index].repuestoservicio.valor = fecha2.diff(fecha1, 'days') / 7;
			}
		}, this);

		console.log(this.dataProblemas);

		this.suma();
		this.tiempo();

	}

	suma() {
		this.totalCosto = 0;
		this.dataProblemas.forEach(element => {
			this.totalCosto += element.repuestoservicio.precio;
		});
	}

	tiempo() {

		let min = 0;
		let hora = 0;
		let dias = 0;
		let seman = 0;
		this.dataProblemas.forEach(element => {
			if (element.repuestoservicio.tipo == 1) {
				let tmpd = moment.utc(element.repuestoservicio.tiempo).format('YYYY-MM-DD HH:mm:ss')
				let resul = (tmpd.split(' ')[1].split(':'));
				min += Number(resul[1]);
				hora += Number(resul[0]);
			}
			if (element.repuestoservicio.tipo == 2) {
				dias += element.repuestoservicio.valor;
			}
			if (element.repuestoservicio.tipo == 3) {
				seman += element.repuestoservicio.valor;
			}
		});


		if (min > 59) {
			let mt = 0, v = 0, v1 = 0;
			v = Math.trunc(min / 60);
			v1 = v * 60;
			mt = min - v1;
			hora = hora + v;
			min = mt;
		}
		if (hora > 8) {
			let ht = 0, v = 0, v1 = 0;
			v = Math.trunc(hora / 8);
			v1 = v * 8;
			ht = hora - v1;
			dias = dias + v;
			hora = ht;
		}
		if (dias > 5) {
			let st = 0, v = 0, v1 = 0;
			v = Math.trunc(dias / 5);
			v1 = v * 5;
			st = dias - v1;
			seman = seman + v;
			dias = st;
		}

		this.minDisplay = min;
		this.horaDisplay = hora;
		this.diasDisplay = dias;
		this.semanaDisplay = seman;
	}

}
