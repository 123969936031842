import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Repuestos } from '../../modelo-repuesto/repuestos.model';
import { RepuestosServices } from '../../modelo-repuesto/servicios-repuestos.services';
import { Modelos } from '../../../reparar-modelos/modelo-modelo/modelos.model';
import { ModelosServices } from '../../../reparar-modelos/modelo-modelo/servicios-modelos.services';

@Component({
	selector: 'app-editarrepuestomodal',
	templateUrl: './editarrepuestomodal.component.html',
	styleUrls: ['./editarrepuestomodal.component.scss']
})

export class EditarRepuestoModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataModelos:any;

	public formEditarNombre: FormGroup;
	public editarNombre:boolean=false;

	public formEditarCalidad: FormGroup;
	public editarCalidad:boolean=false;

	public formEditarCodigobarras: FormGroup;
	public editarCodigobarras:boolean=false;

	public formEditarExistencia: FormGroup;
	public editarExistencia:boolean=false;

	public formEditarPrecio: FormGroup;
	public editarPrecio:boolean=false;

	public formEditarModelo:FormGroup;
	public editarModelo:boolean=false;
	public seleccionarModelo:number;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarRepuestos: any,
		public dialogRef: MatDialogRef <EditarRepuestoModalComponent>,
		private servicioRepuesto:RepuestosServices,
		private servicioModelo:ModelosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.seleccionarModelo=this.dataEditarRepuestos.modelo_id;
		this.servicioModelo.obtenerDatosModelos().subscribe((data:any)=>{
			this.dataModelos=data.modelos;
		});
		this.formEditarModelo= this.fb.group({
			modelo_id:[this.dataEditarRepuestos.modelo_id,Validators.compose([Validators.required])]
		});
	
		this.formEditarNombre= this.fb.group({
			nombre:[this.dataEditarRepuestos.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(100)])],
		});
	
		this.formEditarCalidad= this.fb.group({
			calidad:[this.dataEditarRepuestos.calidad,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	
		this.formEditarCodigobarras= this.fb.group({
			codigobarras:[this.dataEditarRepuestos.codigobarras,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	
		this.formEditarExistencia= this.fb.group({
			existencia:[this.dataEditarRepuestos.existencia,Validators.compose([Validators.required,CustomValidators.number])],
		});
	
		this.formEditarPrecio= this.fb.group({
			precio:[this.dataEditarRepuestos.precio,Validators.compose([Validators.required,CustomValidators.number])],
		});
	
	}

	editRepuestoModeloEnable(){
		this.editarModelo=true;
		this.seleccionarModelo=this.dataEditarRepuestos.modelo_id;
		this.formEditarModelo=this.fb.group({
			modelo_id:[this.dataEditarRepuestos.modelo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editRepuestoModeloDisable(){
		this.seleccionarModelo=this.dataEditarRepuestos.modelo_id;
		this.editarModelo=false;
	}

	saveRepuestoModelo(){
		let RepuestoDataModel:Repuestos=this.formEditarModelo.value;
		RepuestoDataModel.id=this.dataEditarRepuestos.id;
		this.dataEditarRepuestos.modelo_id=RepuestoDataModel.modelo_id;
		this.servicioRepuesto.actulizarRepuestos(RepuestoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarModelo=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRepuestoNombreEnable(){
		this.editarNombre=true;
		this.formEditarNombre=this.fb.group({
			nombre:[this.dataEditarRepuestos.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(100)])],
		});
	}

	editRepuestoNombreDisable(){
		this.editarNombre=false;
	}

	saveRepuestoNombre(){
		let RepuestoDataModel:Repuestos=this.formEditarNombre.value;
		RepuestoDataModel.id=this.dataEditarRepuestos.id;
		this.dataEditarRepuestos.nombre=RepuestoDataModel.nombre;
		this.servicioRepuesto.actulizarRepuestos(RepuestoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarNombre=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRepuestoCalidadEnable(){
		this.editarCalidad=true;
		this.formEditarCalidad=this.fb.group({
			calidad:[this.dataEditarRepuestos.calidad,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	}

	editRepuestoCalidadDisable(){
		this.editarCalidad=false;
	}

	saveRepuestoCalidad(){
		let RepuestoDataModel:Repuestos=this.formEditarCalidad.value;
		RepuestoDataModel.id=this.dataEditarRepuestos.id;
		this.dataEditarRepuestos.calidad=RepuestoDataModel.calidad;
		this.servicioRepuesto.actulizarRepuestos(RepuestoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarCalidad=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRepuestoCodigobarrasEnable(){
		this.editarCodigobarras=true;
		this.formEditarCodigobarras=this.fb.group({
			codigobarras:[this.dataEditarRepuestos.codigobarras,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	}

	editRepuestoCodigobarrasDisable(){
		this.editarCodigobarras=false;
	}

	saveRepuestoCodigobarras(){
		let RepuestoDataModel:Repuestos=this.formEditarCodigobarras.value;
		RepuestoDataModel.id=this.dataEditarRepuestos.id;
		this.dataEditarRepuestos.codigobarras=RepuestoDataModel.codigobarras;
		this.servicioRepuesto.actulizarRepuestos(RepuestoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarCodigobarras=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRepuestoExistenciaEnable(){
		this.editarExistencia=true;
		this.formEditarExistencia=this.fb.group({
			existencia:[this.dataEditarRepuestos.existencia,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editRepuestoExistenciaDisable(){
		this.editarExistencia=false;
	}

	saveRepuestoExistencia(){
		let RepuestoDataModel:Repuestos=this.formEditarExistencia.value;
		RepuestoDataModel.id=this.dataEditarRepuestos.id;
		this.dataEditarRepuestos.existencia=RepuestoDataModel.existencia;
		this.servicioRepuesto.actulizarRepuestos(RepuestoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarExistencia=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRepuestoPrecioEnable(){
		this.editarPrecio=true;
		this.formEditarPrecio=this.fb.group({
			precio:[this.dataEditarRepuestos.precio,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editRepuestoPrecioDisable(){
		this.editarPrecio=false;
	}

	saveRepuestoPrecio(){
		let RepuestoDataModel:Repuestos=this.formEditarPrecio.value;
		RepuestoDataModel.id=this.dataEditarRepuestos.id;
		this.dataEditarRepuestos.precio=RepuestoDataModel.precio;
		this.servicioRepuesto.actulizarRepuestos(RepuestoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarPrecio=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};