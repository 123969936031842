import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Repuestoservicios} from './repuestoservicios.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class RepuestoserviciosServices {

	constructor(private http: HttpClient) { }

	obtenerDatosRepuestoservicios():Observable<Repuestoservicios[]>{
		return this.http.get<Repuestoservicios[]>(Globals.BASE_URL_API_REST+'repuestoservicios.json');
	}

	guardarRepuestoservicios(data:Repuestoservicios){
		return this.http.post(Globals.BASE_URL_API_REST+'repuestoservicios.json',data);
	}

	eliminarRepuestoservicios(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'repuestoservicios/'+id+'.json');
	}

	actulizarRepuestoservicios(data:Repuestoservicios){
		 return this.http.patch(Globals.BASE_URL_API_REST+'repuestoservicios/'+data.id+'.json',data);
	}

	verRepuestoservicios(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'repuestoservicios/'+id+'.json');
	}

	verRepuestoserviciosModelo(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listaderepuestososerviciosproblemas.json',data);
	}
	   
}