import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Repuestosvaloraciones } from '../../modelo-repuestosvaloracione/repuestosvaloraciones.model';
import { RepuestosvaloracionesServices } from '../../modelo-repuestosvaloracione/servicios-repuestosvaloraciones.services';
import { Valoraciones } from '../../../reparar-valoraciones/modelo-valoracione/valoraciones.model';
import { ValoracionesServices } from '../../../reparar-valoraciones/modelo-valoracione/servicios-valoraciones.services';
import { Repuestos } from '../../../reparar-repuestos/modelo-repuesto/repuestos.model';
import { RepuestosServices } from '../../../reparar-repuestos/modelo-repuesto/servicios-repuestos.services';

@Component({
	selector: 'app-editarrepuestosvaloracionemodal',
	templateUrl: './editarrepuestosvaloracionemodal.component.html',
	styleUrls: ['./editarrepuestosvaloracionemodal.component.scss']
})

export class EditarRepuestosvaloracioneModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataValoraciones:any;
	public dataRepuestos:any;

	public formEditarValoracione:FormGroup;
	public editarValoracione:boolean=false;
	public seleccionarValoracione:number;

	public formEditarRepuesto:FormGroup;
	public editarRepuesto:boolean=false;
	public seleccionarRepuesto:number;

	public formEditarPrecioventa: FormGroup;
	public editarPrecioventa:boolean=false;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarRepuestosvaloraciones: any,
		public dialogRef: MatDialogRef <EditarRepuestosvaloracioneModalComponent>,
		private servicioRepuestosvaloracione:RepuestosvaloracionesServices,
		private servicioValoracione:ValoracionesServices,
		private servicioRepuesto:RepuestosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.seleccionarValoracione=this.dataEditarRepuestosvaloraciones.valoracione_id;
		this.servicioValoracione.obtenerDatosValoraciones().subscribe((data:any)=>{
			this.dataValoraciones=data.valoraciones;
		});
		this.formEditarValoracione= this.fb.group({
			valoracione_id:[this.dataEditarRepuestosvaloraciones.valoracione_id,Validators.compose([Validators.required])]
		});
	
		this.seleccionarRepuesto=this.dataEditarRepuestosvaloraciones.repuesto_id;
		this.servicioRepuesto.obtenerDatosRepuestos().subscribe((data:any)=>{
			this.dataRepuestos=data.repuestos;
		});
		this.formEditarRepuesto= this.fb.group({
			repuesto_id:[this.dataEditarRepuestosvaloraciones.repuesto_id,Validators.compose([Validators.required])]
		});
	
		this.formEditarPrecioventa= this.fb.group({
			precioventa:[this.dataEditarRepuestosvaloraciones.precioventa,Validators.compose([Validators.required,CustomValidators.number])],
		});
	
	}

	editRepuestosvaloracioneValoracioneEnable(){
		this.editarValoracione=true;
		this.seleccionarValoracione=this.dataEditarRepuestosvaloraciones.valoracione_id;
		this.formEditarValoracione=this.fb.group({
			valoracione_id:[this.dataEditarRepuestosvaloraciones.valoracione_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editRepuestosvaloracioneValoracioneDisable(){
		this.seleccionarValoracione=this.dataEditarRepuestosvaloraciones.valoracione_id;
		this.editarValoracione=false;
	}

	saveRepuestosvaloracioneValoracione(){
		let RepuestosvaloracioneDataModel:Repuestosvaloraciones=this.formEditarValoracione.value;
		RepuestosvaloracioneDataModel.id=this.dataEditarRepuestosvaloraciones.id;
		this.dataEditarRepuestosvaloraciones.valoracione_id=RepuestosvaloracioneDataModel.valoracione_id;
		this.servicioRepuestosvaloracione.actulizarRepuestosvaloraciones(RepuestosvaloracioneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarValoracione=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRepuestosvaloracioneRepuestoEnable(){
		this.editarRepuesto=true;
		this.seleccionarRepuesto=this.dataEditarRepuestosvaloraciones.repuesto_id;
		this.formEditarRepuesto=this.fb.group({
			repuesto_id:[this.dataEditarRepuestosvaloraciones.repuesto_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editRepuestosvaloracioneRepuestoDisable(){
		this.seleccionarRepuesto=this.dataEditarRepuestosvaloraciones.repuesto_id;
		this.editarRepuesto=false;
	}

	saveRepuestosvaloracioneRepuesto(){
		let RepuestosvaloracioneDataModel:Repuestosvaloraciones=this.formEditarRepuesto.value;
		RepuestosvaloracioneDataModel.id=this.dataEditarRepuestosvaloraciones.id;
		this.dataEditarRepuestosvaloraciones.repuesto_id=RepuestosvaloracioneDataModel.repuesto_id;
		this.servicioRepuestosvaloracione.actulizarRepuestosvaloraciones(RepuestosvaloracioneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarRepuesto=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editRepuestosvaloracionePrecioventaEnable(){
		this.editarPrecioventa=true;
		this.formEditarPrecioventa=this.fb.group({
			precioventa:[this.dataEditarRepuestosvaloraciones.precioventa,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editRepuestosvaloracionePrecioventaDisable(){
		this.editarPrecioventa=false;
	}

	saveRepuestosvaloracionePrecioventa(){
		let RepuestosvaloracioneDataModel:Repuestosvaloraciones=this.formEditarPrecioventa.value;
		RepuestosvaloracioneDataModel.id=this.dataEditarRepuestosvaloraciones.id;
		this.dataEditarRepuestosvaloraciones.precioventa=RepuestosvaloracioneDataModel.precioventa;
		this.servicioRepuestosvaloracione.actulizarRepuestosvaloraciones(RepuestosvaloracioneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarPrecioventa=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};