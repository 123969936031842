import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Reportes } from '../model/reporte.model';
import { Globals } from '../../../global/global.model';



@Injectable()
export class ReportesServices {

    constructor(private http: HttpClient) {}

    generatePdfRecepciones(data:Reportes){
        return this.http.post(Globals.BASE_URL_API_REST+'reportes.json',data);
    }

    generatePdfRecepcionesSinCache(data:any){
        return this.http.put(Globals.BASE_URL_API_REST+'reportes.json',data);
    }

    generatePdfRecepcionesTecnico(data:Reportes){
        return this.http.patch(Globals.BASE_URL_API_REST+'reportes.json',data);
    }

    generatePdfFacturas(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'facturas.json',data);
    }

    generatePorDia(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'consultas.json',data);
    }

    generatePdfRecepcionesPorDia(data){
        return this.http.put(Globals.BASE_URL_API_REST+'consultas.json',data);
    }

}