import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Observaciones} from './observaciones.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Recepciones } from 'app/appmantenimiento/reparar-recepciones/modelo-recepcione/recepciones.model';


@Injectable()
export class ObservacionesServices {

	constructor(private http: HttpClient) { }

	obtenerDatosObservaciones():Observable<Observaciones[]>{
		return this.http.get<Observaciones[]>(Globals.BASE_URL_API_REST+'observaciones.json');
	}

	guardarObservaciones(data:Observaciones){
		return this.http.post(Globals.BASE_URL_API_REST+'observaciones.json',data);
	}

	eliminarObservaciones(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'observaciones/'+id+'.json');
	}

	actulizarObservaciones(data:Observaciones){
		 return this.http.patch(Globals.BASE_URL_API_REST+'observaciones/'+data.id+'.json',data);
	}

	verObservaciones(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'observaciones/'+id+'.json');
	}

	verObservacionesPorRecepcion(data:Recepciones){
		 return this.http.post(Globals.BASE_URL_API_REST+'listadeobservaciones.json',data);
	}

	
}