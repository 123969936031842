import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Equipoclientes} from './equipoclientes.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Clientes } from 'app/appmantenimiento/reparar-clientes/modelo-cliente/clientes.model';
import { Modelos } from 'app/appmantenimiento/reparar-modelos/modelo-modelo/modelos.model';


@Injectable()
export class EquipoclientesServices {

	constructor(private http: HttpClient) { }

	obtenerDatosEquipoclientes():Observable<Equipoclientes[]>{
		return this.http.get<Equipoclientes[]>(Globals.BASE_URL_API_REST+'equipoclientes.json');
	}

	guardarEquipoclientes(data:Equipoclientes){
		return this.http.post(Globals.BASE_URL_API_REST+'equipoclientes.json',data);
	}

	eliminarEquipoclientes(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'equipoclientes/'+id+'.json');
	}

	actulizarEquipoclientes(data:Equipoclientes){
		 return this.http.patch(Globals.BASE_URL_API_REST+'equipoclientes/'+data.id+'.json',data);
	}

	verEquipoclientes(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'equipoclientes/'+id+'.json');
	}

	verEquipoPorCliente(data:Clientes){
		return this.http.post(Globals.BASE_URL_API_REST+'listabuscarce.json',data);
	}
	
	verEquipoPorModelo(data:Modelos){
		return this.http.put(Globals.BASE_URL_API_REST+'listabuscarce.json',data);
	}

	pagosEquipoPorCliente(data:Clientes){
		return this.http.post(Globals.BASE_URL_API_REST+'pagosequiposclientes.json',data);
	}
	
	   
}