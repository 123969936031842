import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {MatInputModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatListModule,
		MatGridListModule,
		MatProgressBarModule,
		MatMenuModule,
		MatButtonToggleModule,
		MatToolbarModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RecepcionistasRoutes } from './recepcionistas.routing';
import { RecepcionistaListaComponent } from './view-recepcionista/recepcionistavista.component';
import { InforecepcionistaComponent } from './inforecepcionista/inforecepcionista.component';
@NgModule({
	imports: [
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	RouterModule.forChild(RecepcionistasRoutes),
	MatIconModule,
	MatCardModule,
	MatButtonModule,
	ReactiveFormsModule,
	MatListModule,
	MatProgressBarModule,
	MatMenuModule,
	MatToolbarModule,
	FlexLayoutModule,
	MatInputModule,
	MatButtonToggleModule,
	MatGridListModule,
	NgxDatatableModule
],
declarations: [
	RecepcionistaListaComponent,
	InforecepcionistaComponent
],
exports:[
	InforecepcionistaComponent
]
})
export class RecepcionistasModule {}
