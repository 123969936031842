import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Observaciones } from '../../modelo-observacione/observaciones.model';
import { ObservacionesServices } from '../../modelo-observacione/servicios-observaciones.services';
import { Recepciones } from '../../../reparar-recepciones/modelo-recepcione/recepciones.model';
import { RecepcionesServices } from '../../../reparar-recepciones/modelo-recepcione/servicios-recepciones.services';

@Component({
	selector: 'app-insertarobservacionemodal',
	templateUrl: './insertarobservacionemodal.component.html',
	styleUrls: ['./insertarobservacionemodal.component.scss']
})

export class InsertarObservacioneModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public formInsertarObservacione: FormGroup;

	constructor(private fb: FormBuilder,
		public dialogRef: MatDialogRef <InsertarObservacioneModalComponent>,
		@Inject(MAT_DIALOG_DATA) public dataRecepcion: any,
		private servicioObservacione:ObservacionesServices,
		private servicioRecepcione:RecepcionesServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "45%");
		}else{
			this.dialogRef.updateSize("100%", "70%");
		}
		
		this.formInsertarObservacione = this.fb.group({
			descripcion:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
			estado:[1,null],
			recepcione_id:[this.dataRecepcion.id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}


	saveObservacione(){
		let observacioneDataModel:Observaciones=this.formInsertarObservacione.value;
		this.servicioObservacione.guardarObservaciones(observacioneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.dialogRef.close(1);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}


	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "45%");
		}else{
			this.dialogRef.updateSize("100%", "70%");
		}
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};