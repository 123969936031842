import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppauthModule } from '../appauth/appauth.module';
import { AsignacionesServices } from './reparar-asignaciones/modelo-asignacione/servicios-asignaciones.services';
import { AsignacionesModalModule } from './reparar-asignaciones/modales-asignacione/asignacionesmodal.module';
import { ClientesServices } from './reparar-clientes/modelo-cliente/servicios-clientes.services';
import { ClientesModalModule } from './reparar-clientes/modales-cliente/clientesmodal.module';
import { DispositivosServices } from './reparar-dispositivos/modelo-dispositivo/servicios-dispositivos.services';
import { DispositivosModalModule } from './reparar-dispositivos/modales-dispositivo/dispositivosmodal.module';
import { EquipoclientesServices } from './reparar-equipoclientes/modelo-equipocliente/servicios-equipoclientes.services';
import { EquipoclientesModalModule } from './reparar-equipoclientes/modales-equipocliente/equipoclientesmodal.module';
import { EquiposServices } from './reparar-equipos/modelo-equipo/servicios-equipos.services';
import { EquiposModalModule } from './reparar-equipos/modales-equipo/equiposmodal.module';
import { FacturaServices } from './reparar-factura/modelo-factur/servicios-factura.services';
import { FacturaModalModule } from './reparar-factura/modales-factur/facturamodal.module';
import { MarcasServices } from './reparar-marcas/modelo-marca/servicios-marcas.services';
import { MarcasModalModule } from './reparar-marcas/modales-marca/marcasmodal.module';
import { ModelosServices } from './reparar-modelos/modelo-modelo/servicios-modelos.services';
import { ModelosModalModule } from './reparar-modelos/modales-modelo/modelosmodal.module';
import { ObservacionesServices } from './reparar-observaciones/modelo-observacione/servicios-observaciones.services';
import { ObservacionesModalModule } from './reparar-observaciones/modales-observacione/observacionesmodal.module';
import { ProblemasServices } from './reparar-problemas/modelo-problema/servicios-problemas.services';
import { ProblemasModalModule } from './reparar-problemas/modales-problema/problemasmodal.module';
import { RecepcionesServices } from './reparar-recepciones/modelo-recepcione/servicios-recepciones.services';
import { RecepcionesModalModule } from './reparar-recepciones/modales-recepcione/recepcionesmodal.module';
import { RecepcionistasServices } from './reparar-recepcionistas/modelo-recepcionista/servicios-recepcionistas.services';
import { RecepcionistasModalModule } from './reparar-recepcionistas/modales-recepcionista/recepcionistasmodal.module';
import { RepuestosServices } from './reparar-repuestos/modelo-repuesto/servicios-repuestos.services';
import { RepuestosModalModule } from './reparar-repuestos/modales-repuesto/repuestosmodal.module';
import { RepuestoserviciosServices } from './reparar-repuestoservicios/modelo-repuestoservicio/servicios-repuestoservicios.services';
import { RepuestoserviciosModalModule } from './reparar-repuestoservicios/modales-repuestoservicio/repuestoserviciosmodal.module';
import { RepuestosvaloracionesServices } from './reparar-repuestosvaloraciones/modelo-repuestosvaloracione/servicios-repuestosvaloraciones.services';
import { RepuestosvaloracionesModalModule } from './reparar-repuestosvaloraciones/modales-repuestosvaloracione/repuestosvaloracionesmodal.module';
import { TecnicosServices } from './reparar-tecnicos/modelo-tecnico/servicios-tecnicos.services';
import { TecnicosModalModule } from './reparar-tecnicos/modales-tecnico/tecnicosmodal.module';
import { ValoracionesServices } from './reparar-valoraciones/modelo-valoracione/servicios-valoraciones.services';
import { ValoracionesModalModule } from './reparar-valoraciones/modales-valoracione/valoracionesmodal.module';
import { ModalFotoRecepcionistasModule } from './reparar-fotorecepcionistas/modalfotorecepcionista.module';
import { FotoRecepcionistasServices } from './reparar-fotorecepcionistas/fotorecepcionista/fotorecepcionistas.services';
import { customValidatorMondogo } from './custom-validator/customValidator.services';
import { FotoTecnicosServices } from './reparar-fototecnicos/fototecnico/fototecnicos.services';
import { ModalFotoTecnicosModule } from './reparar-fototecnicos/modalfototecnico.module';
import { FotoClientesServices } from './reparar-fotoclientes/fotocliente/fotoclientes.services';
import { ModalFotoClientesModule } from './reparar-fotoclientes/modalfotocliente.module';
import { ModalFotoEquiposModule } from './reparar-fotoequipos/modalfotoequipo.module';
import { FotoEquiposServices } from './reparar-fotoequipos/fotoequipo/fotoequipos.services';
import { ReportesServices } from './reparar-reportes/reportes/reportes.services';
import { ReportesMantenimientoModule } from './reparar-reportes/reportes.module';

@NgModule({
	imports:[
		CommonModule,
		AppauthModule,
		AsignacionesModalModule,
		ClientesModalModule,
		DispositivosModalModule,
		EquipoclientesModalModule,
		EquiposModalModule,
		FacturaModalModule,
		MarcasModalModule,
		ModelosModalModule,
		ObservacionesModalModule,
		ProblemasModalModule,
		RecepcionesModalModule,
		RecepcionistasModalModule,
		RepuestosModalModule,
		RepuestoserviciosModalModule,
		RepuestosvaloracionesModalModule,
		TecnicosModalModule,
		ValoracionesModalModule,
		ModalFotoRecepcionistasModule,
		ModalFotoTecnicosModule,
		ModalFotoClientesModule,
		ModalFotoEquiposModule,
		ReportesMantenimientoModule
	],
	declarations:[
	],
	providers:[
		AsignacionesServices,
		ClientesServices,
		DispositivosServices,
		EquipoclientesServices,
		EquiposServices,
		FacturaServices,
		MarcasServices,
		ModelosServices,
		ObservacionesServices,
		ProblemasServices,
		RecepcionesServices,
		RecepcionistasServices,
		RepuestosServices,
		RepuestoserviciosServices,
		RepuestosvaloracionesServices,
		TecnicosServices,
		ValoracionesServices,
		FotoRecepcionistasServices,
		customValidatorMondogo,
		FotoTecnicosServices,
		FotoClientesServices,
		FotoEquiposServices,
		ReportesServices
	],
})
export class AppmantenimientoModule {}
