import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReportesRoutes } from './reportes.routing';
import { ImprimirComponent } from './imprimir/imprimir.component';
import { ListadiariasComponent } from './listadiarias/listadiarias.component';
import { MatIconModule, MatCardModule, MatButtonModule, MatListModule, MatProgressBarModule, MatMenuModule, MatToolbarModule, MatInputModule, MatButtonToggleModule, MatGridListModule, MatSelectModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(ReportesRoutes),
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		ReactiveFormsModule,
		MatListModule,
		MatProgressBarModule,
		MatMenuModule,
		MatToolbarModule,
		FlexLayoutModule,
		MatInputModule,
		MatButtonToggleModule,
		MatGridListModule,
		MatSelectModule,
		NgxDatatableModule
	],
	declarations:[
		ImprimirComponent,
		ListadiariasComponent
	],
	entryComponents: [
		ImprimirComponent
	]
})

export class ReportesMantenimientoModule {}
