import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Equipos} from './equipos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class EquiposServices {

	constructor(private http: HttpClient) { }

	obtenerDatosEquipos():Observable<Equipos[]>{
		return this.http.get<Equipos[]>(Globals.BASE_URL_API_REST+'equipos.json');
	}

	guardarEquipos(data:Equipos){
		return this.http.post(Globals.BASE_URL_API_REST+'equipos.json',data);
	}

	eliminarEquipos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'equipos/'+id+'.json');
	}

	actulizarEquipos(data:Equipos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'equipos/'+data.id+'.json',data);
	}

	verEquipos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'equipos/'+id+'.json');
	}
}