import { AuthModule } from '../appauth/auth/auth.module';
import {UserServices} from '../appauth/user-pages/user/users.services';
import {AuthInterceptor} from '../appauth/interceptors/authInterceptor';
import {HTTP_INTERCEPTORS,HttpClientModule} from '@angular/common/http';
import {AuthGuard} from '../appauth/auth/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';

@NgModule({
  imports: [
    CommonModule,
    AuthModule,
    HttpClientModule
  ],
  declarations: [
  ],
  providers:[
    {
      provide:HTTP_INTERCEPTORS,
      useClass:AuthInterceptor,
      multi:true
    },
    UserServices,
    AuthGuard,
    HeaderComponent
  ]
})

export class MenuModule {}
