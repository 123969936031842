import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import {  MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { Users } from '../../user/users.model';
import { CustomValidators } from 'ng2-validation';
import {UserServices} from '../../user/users.services';
import {NotificationServices} from '../../../notification/notificatio.services';
import {GroupsServices} from '../../../group-pages/groups/groups.services';

@Component({
    selector: 'app-add-user',
    templateUrl:'./useraddmodal-component.html',
    encapsulation: ViewEncapsulation.None
  })
  export class UserAddModalComponent implements OnInit {
    public formAddUser: FormGroup;
    breakpoint: number;
    ratio = '4:1';
    groupdata:any;
    constructor(public dialogRef: MatDialogRef <UserAddModalComponent>,
                private fb: FormBuilder,
                private servicesUser:UserServices,
                private servicesGroup:GroupsServices,
                private Noti:NotificationServices,) {}
    
    ngOnInit(){

      this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
      this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
      if(this.breakpoint!=1){
        this.dialogRef.updateSize("50%", "60%");
      }else{
        this.dialogRef.updateSize("100%", "80%");
      }

      this.servicesGroup.getDataGroups().subscribe((data:any)=>{
        this.groupdata=data.groups;
        
      });
      this.formAddUser = this.fb.group({
        username: [null, Validators.compose([Validators.required, CustomValidators.email])],
        fullname: [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(250)])],
        identificador: [null,null],
        group_id: [null, Validators.compose([Validators.required])],
        password: [null, Validators.compose([Validators.required])],
        estado: [1,null],
      });
    }

    saveUser(){
      
      let userDataModel:Users=this.formAddUser.value;
      console.log(userDataModel);
      
      this.servicesUser.saveUser(userDataModel).subscribe((data:any)=>{
        if(data.estado){
          this.Noti.setNotification(data).then((datas)=>{
              if(datas){
                this.dialogRef.close(1);
              }
          });
        
        }else{
          this.Noti.setNotification(data);
        }
      });
    }

    onResize(event) {
      this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
      this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
      if(this.breakpoint!=1){
        this.dialogRef.updateSize("50%", "60%");
      }else{
        this.dialogRef.updateSize("100%", "80%");
      }
    }
    
    cerrarModal(){
      this.dialogRef.close(2);
    }

  }

  MatDialogRef.prototype.updateSize = function (width, height) {
    if (width === void 0) { width = 'auto'; }
    if (height === void 0) { height = 'auto'; }
    this._getPositionStrategy().width(width).height(height);
    this._overlayRef.updatePosition();
    return this;
  };
  