import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FotoClientesComponent } from './foto/fotocliente.compente';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[FotoClientesComponent],
	entryComponents:[FotoClientesComponent],
})
export class ModalFotoClientesModule { }
