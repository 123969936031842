import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Problemas } from '../../modelo-problema/problemas.model';
import { ProblemasServices } from '../../modelo-problema/servicios-problemas.services';
import { Recepciones } from '../../../reparar-recepciones/modelo-recepcione/recepciones.model';
import { RecepcionesServices } from '../../../reparar-recepciones/modelo-recepcione/servicios-recepciones.services';
import { Repuestoservicios } from '../../../reparar-repuestoservicios/modelo-repuestoservicio/repuestoservicios.model';
import { RepuestoserviciosServices } from '../../../reparar-repuestoservicios/modelo-repuestoservicio/servicios-repuestoservicios.services';

@Component({
	selector: 'app-editarproblemamodal',
	templateUrl: './editarproblemamodal.component.html',
	styleUrls: ['./editarproblemamodal.component.scss']
})

export class EditarProblemaModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataRecepciones:any;
	public dataRepuestoservicios:any;

	public formEditarDescripcion: FormGroup;
	public editarDescripcion:boolean=false;


	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarProblemas: any,
		public dialogRef: MatDialogRef <EditarProblemaModalComponent>,
		private servicioProblema:ProblemasServices,
		private servicioRecepcione:RecepcionesServices,
		private servicioRepuestoservicio:RepuestoserviciosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
		console.log(this.dataEditarProblemas);
		
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.formEditarDescripcion= this.fb.group({
			descripcion:[this.dataEditarProblemas.descripcion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	
	}

	editProblemaDescripcionEnable(){
		this.editarDescripcion=true;
		this.formEditarDescripcion=this.fb.group({
			descripcion:[this.dataEditarProblemas.descripcion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	}

	editProblemaDescripcionDisable(){
		this.editarDescripcion=false;
	}

	saveProblemaDescripcion(){
		let ProblemaDataModel:Problemas=this.formEditarDescripcion.value;
		ProblemaDataModel.id=this.dataEditarProblemas.id;
		this.dataEditarProblemas.descripcion=ProblemaDataModel.descripcion;
		this.servicioProblema.actulizarProblemas(ProblemaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarDescripcion=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};