import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Factura } from '../../modelo-factur/factura.model';
import { FacturaServices } from '../../modelo-factur/servicios-factura.services';
import { Asignaciones } from '../../../reparar-asignaciones/modelo-asignacione/asignaciones.model';
import { AsignacionesServices } from '../../../reparar-asignaciones/modelo-asignacione/servicios-asignaciones.services';

@Component({
	selector: 'app-editarfacturmodal',
	templateUrl: './editarfacturmodal.component.html',
	styleUrls: ['./editarfacturmodal.component.scss']
})

export class EditarFacturModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataAsignaciones:any;

	public formEditarTotal: FormGroup;
	public editarTotal:boolean=false;

	public formEditarFecha: FormGroup;
	public editarFecha:boolean=false;

	public formEditarAsignacione:FormGroup;
	public editarAsignacione:boolean=false;
	public seleccionarAsignacione:number;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarFactura: any,
		public dialogRef: MatDialogRef <EditarFacturModalComponent>,
		private servicioFactur:FacturaServices,
		private servicioAsignacione:AsignacionesServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.seleccionarAsignacione=this.dataEditarFactura.asignacione_id;
		this.servicioAsignacione.obtenerDatosAsignaciones().subscribe((data:any)=>{
			this.dataAsignaciones=data.asignaciones;
		});
		this.formEditarAsignacione= this.fb.group({
			asignacione_id:[this.dataEditarFactura.asignacione_id,Validators.compose([Validators.required])]
		});
	
		this.formEditarTotal= this.fb.group({
			total:[this.dataEditarFactura.total,Validators.compose([Validators.required,CustomValidators.number])],
		});
	
		var datePipe = new DatePipe("es-EC");
		this.formEditarFecha= this.fb.group({
			fecha:[datePipe.transform(this.dataEditarFactura.fecha, 'yyyy-MM-dd'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	
	}

	editFacturAsignacioneEnable(){
		this.editarAsignacione=true;
		this.seleccionarAsignacione=this.dataEditarFactura.asignacione_id;
		this.formEditarAsignacione=this.fb.group({
			asignacione_id:[this.dataEditarFactura.asignacione_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editFacturAsignacioneDisable(){
		this.seleccionarAsignacione=this.dataEditarFactura.asignacione_id;
		this.editarAsignacione=false;
	}

	saveFacturAsignacione(){
		let FacturDataModel:Factura=this.formEditarAsignacione.value;
		FacturDataModel.id=this.dataEditarFactura.id;
		this.dataEditarFactura.asignacione_id=FacturDataModel.asignacione_id;
		this.servicioFactur.actulizarFactura(FacturDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarAsignacione=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editFacturTotalEnable(){
		this.editarTotal=true;
		this.formEditarTotal=this.fb.group({
			total:[this.dataEditarFactura.total,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editFacturTotalDisable(){
		this.editarTotal=false;
	}

	saveFacturTotal(){
		let FacturDataModel:Factura=this.formEditarTotal.value;
		FacturDataModel.id=this.dataEditarFactura.id;
		this.dataEditarFactura.total=FacturDataModel.total;
		this.servicioFactur.actulizarFactura(FacturDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarTotal=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editFacturFechaEnable(){
		this.editarFecha=true;
		var datePipe = new DatePipe("es-EC");
		this.formEditarFecha=this.fb.group({
			fecha:[datePipe.transform(this.dataEditarFactura.fecha, 'yyyy-MM-dd'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editFacturFechaDisable(){
		this.editarFecha=false;
	}

	saveFacturFecha(){
		let FacturDataModel:Factura=this.formEditarFecha.value;
		FacturDataModel.id=this.dataEditarFactura.id;
		this.dataEditarFactura.fecha=FacturDataModel.fecha;
		this.servicioFactur.actulizarFactura(FacturDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarFecha=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};