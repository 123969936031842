import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Tecnicos } from '../../modelo-tecnico/tecnicos.model';
import { TecnicosServices } from '../../modelo-tecnico/servicios-tecnicos.services';
import { customValidatorMondogo } from 'app/appmantenimiento/custom-validator/customValidator.services';

@Component({
	selector: 'app-editartecnicomodal',
	templateUrl: './editartecnicomodal.component.html',
	styleUrls: ['./editartecnicomodal.component.scss']
})

export class EditarTecnicoModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';

	public formEditarCedula: FormGroup;
	public editarCedula:boolean=false;

	public formEditarNombre: FormGroup;
	public editarNombre:boolean=false;

	public formEditarApellido: FormGroup;
	public editarApellido:boolean=false;

	public formEditarTelefono: FormGroup;
	public editarTelefono:boolean=false;

	public formEditarCelular: FormGroup;
	public editarCelular:boolean=false;

	public formEditarCiudad: FormGroup;
	public editarCiudad:boolean=false;

	public formEditarDireccion: FormGroup;
	public editarDireccion:boolean=false;

	public formEditarMail: FormGroup;
	public editarMail:boolean=false;

	public formEditarRevisiones: FormGroup;
	public editarRevisiones:boolean=false;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarTecnicos: any,
		public dialogRef: MatDialogRef <EditarTecnicoModalComponent>,
		private validatciones:customValidatorMondogo,
		private servicioTecnico:TecnicosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.formEditarCedula= this.fb.group({
			cedula: [this.dataEditarTecnicos.cedula, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10),this.validatciones.digitoVerificador])],
		});
	
		this.formEditarNombre= this.fb.group({
			nombre:[this.dataEditarTecnicos.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	
		this.formEditarApellido= this.fb.group({
			apellido:[this.dataEditarTecnicos.apellido,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	
		this.formEditarTelefono= this.fb.group({
			telefono:[this.dataEditarTecnicos.telefono,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
		});
	
		this.formEditarCelular= this.fb.group({
			celular:[this.dataEditarTecnicos.celular,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	
		this.formEditarCiudad= this.fb.group({
			ciudad:[this.dataEditarTecnicos.ciudad,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(20)])],
		});
	
		this.formEditarDireccion= this.fb.group({
			direccion:[this.dataEditarTecnicos.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	
		this.formEditarMail= this.fb.group({
			mail:[this.dataEditarTecnicos.mail,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150),CustomValidators.email])],
		});
	
		this.formEditarRevisiones= this.fb.group({
			revisiones:[this.dataEditarTecnicos.revisiones,Validators.compose([Validators.required,CustomValidators.number])],
		});
	
	}

	editTecnicoCedulaEnable(){
		this.editarCedula=true;
		this.formEditarCedula=this.fb.group({
			cedula: [this.dataEditarTecnicos.cedula, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10),this.validatciones.digitoVerificador])],
		});
	}

	editTecnicoCedulaDisable(){
		this.editarCedula=false;
	}

	saveTecnicoCedula(){
		let TecnicoDataModel:Tecnicos=this.formEditarCedula.value;
		TecnicoDataModel.id=this.dataEditarTecnicos.id;
		this.dataEditarTecnicos.cedula=TecnicoDataModel.cedula;
		this.servicioTecnico.actulizarTecnicos(TecnicoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarCedula=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editTecnicoNombreEnable(){
		this.editarNombre=true;
		this.formEditarNombre=this.fb.group({
			nombre:[this.dataEditarTecnicos.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	}

	editTecnicoNombreDisable(){
		this.editarNombre=false;
	}

	saveTecnicoNombre(){
		let TecnicoDataModel:Tecnicos=this.formEditarNombre.value;
		TecnicoDataModel.id=this.dataEditarTecnicos.id;
		this.dataEditarTecnicos.nombre=TecnicoDataModel.nombre;
		this.servicioTecnico.actulizarTecnicos(TecnicoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarNombre=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editTecnicoApellidoEnable(){
		this.editarApellido=true;
		this.formEditarApellido=this.fb.group({
			apellido:[this.dataEditarTecnicos.apellido,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	}

	editTecnicoApellidoDisable(){
		this.editarApellido=false;
	}

	saveTecnicoApellido(){
		let TecnicoDataModel:Tecnicos=this.formEditarApellido.value;
		TecnicoDataModel.id=this.dataEditarTecnicos.id;
		this.dataEditarTecnicos.apellido=TecnicoDataModel.apellido;
		this.servicioTecnico.actulizarTecnicos(TecnicoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarApellido=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editTecnicoTelefonoEnable(){
		this.editarTelefono=true;
		this.formEditarTelefono=this.fb.group({
			telefono:[this.dataEditarTecnicos.telefono,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9)])],
		});
	}

	editTecnicoTelefonoDisable(){
		this.editarTelefono=false;
	}

	saveTecnicoTelefono(){
		let TecnicoDataModel:Tecnicos=this.formEditarTelefono.value;
		TecnicoDataModel.id=this.dataEditarTecnicos.id;
		this.dataEditarTecnicos.telefono=TecnicoDataModel.telefono;
		this.servicioTecnico.actulizarTecnicos(TecnicoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarTelefono=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editTecnicoCelularEnable(){
		this.editarCelular=true;
		this.formEditarCelular=this.fb.group({
			celular:[this.dataEditarTecnicos.celular,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10)])],
		});
	}

	editTecnicoCelularDisable(){
		this.editarCelular=false;
	}

	saveTecnicoCelular(){
		let TecnicoDataModel:Tecnicos=this.formEditarCelular.value;
		TecnicoDataModel.id=this.dataEditarTecnicos.id;
		this.dataEditarTecnicos.celular=TecnicoDataModel.celular;
		this.servicioTecnico.actulizarTecnicos(TecnicoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarCelular=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editTecnicoCiudadEnable(){
		this.editarCiudad=true;
		this.formEditarCiudad=this.fb.group({
			ciudad:[this.dataEditarTecnicos.ciudad,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(20)])],
		});
	}

	editTecnicoCiudadDisable(){
		this.editarCiudad=false;
	}

	saveTecnicoCiudad(){
		let TecnicoDataModel:Tecnicos=this.formEditarCiudad.value;
		TecnicoDataModel.id=this.dataEditarTecnicos.id;
		this.dataEditarTecnicos.ciudad=TecnicoDataModel.ciudad;
		this.servicioTecnico.actulizarTecnicos(TecnicoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarCiudad=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editTecnicoDireccionEnable(){
		this.editarDireccion=true;
		this.formEditarDireccion=this.fb.group({
			direccion:[this.dataEditarTecnicos.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	}

	editTecnicoDireccionDisable(){
		this.editarDireccion=false;
	}

	saveTecnicoDireccion(){
		let TecnicoDataModel:Tecnicos=this.formEditarDireccion.value;
		TecnicoDataModel.id=this.dataEditarTecnicos.id;
		this.dataEditarTecnicos.direccion=TecnicoDataModel.direccion;
		this.servicioTecnico.actulizarTecnicos(TecnicoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarDireccion=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editTecnicoMailEnable(){
		this.editarMail=true;
		this.formEditarMail=this.fb.group({
			mail:[this.dataEditarTecnicos.mail,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150),CustomValidators.email])],
		});
	}

	editTecnicoMailDisable(){
		this.editarMail=false;
	}

	saveTecnicoMail(){
		let TecnicoDataModel:Tecnicos=this.formEditarMail.value;
		TecnicoDataModel.id=this.dataEditarTecnicos.id;
		this.dataEditarTecnicos.mail=TecnicoDataModel.mail;
		this.servicioTecnico.actulizarTecnicos(TecnicoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarMail=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editTecnicoRevisionesEnable(){
		this.editarRevisiones=true;
		this.formEditarRevisiones=this.fb.group({
			revisiones:[this.dataEditarTecnicos.revisiones,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editTecnicoRevisionesDisable(){
		this.editarRevisiones=false;
	}

	saveTecnicoRevisiones(){
		let TecnicoDataModel:Tecnicos=this.formEditarRevisiones.value;
		TecnicoDataModel.id=this.dataEditarTecnicos.id;
		this.dataEditarTecnicos.revisiones=TecnicoDataModel.revisiones;
		this.servicioTecnico.actulizarTecnicos(TecnicoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarRevisiones=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};