import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Observaciones } from '../../modelo-observacione/observaciones.model';
import { ObservacionesServices } from '../../modelo-observacione/servicios-observaciones.services';
import { Recepciones } from '../../../reparar-recepciones/modelo-recepcione/recepciones.model';
import { RecepcionesServices } from '../../../reparar-recepciones/modelo-recepcione/servicios-recepciones.services';

@Component({
	selector: 'app-editarobservacionemodal',
	templateUrl: './editarobservacionemodal.component.html',
	styleUrls: ['./editarobservacionemodal.component.scss']
})

export class EditarObservacioneModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataRecepciones:any;

	public formEditarDescripcion: FormGroup;
	public editarDescripcion:boolean=false;

	public formEditarRecepcione:FormGroup;
	public editarRecepcione:boolean=false;
	public seleccionarRecepcione:number;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarObservaciones: any,
		public dialogRef: MatDialogRef <EditarObservacioneModalComponent>,
		private servicioObservacione:ObservacionesServices,
		private servicioRecepcione:RecepcionesServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "40%");
		}else{
			this.dialogRef.updateSize("100%", "70%");
		}
	
		this.seleccionarRecepcione=this.dataEditarObservaciones.recepcione_id;
		this.servicioRecepcione.obtenerDatosRecepciones().subscribe((data:any)=>{
			this.dataRecepciones=data.recepciones;
		});
		this.formEditarRecepcione= this.fb.group({
			recepcione_id:[this.dataEditarObservaciones.recepcione_id,Validators.compose([Validators.required])]
		});
	
		this.formEditarDescripcion= this.fb.group({
			descripcion:[this.dataEditarObservaciones.descripcion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	
	}

	editObservacioneRecepcioneEnable(){
		this.editarRecepcione=true;
		this.seleccionarRecepcione=this.dataEditarObservaciones.recepcione_id;
		this.formEditarRecepcione=this.fb.group({
			recepcione_id:[this.dataEditarObservaciones.recepcione_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editObservacioneRecepcioneDisable(){
		this.seleccionarRecepcione=this.dataEditarObservaciones.recepcione_id;
		this.editarRecepcione=false;
	}

	saveObservacioneRecepcione(){
		let ObservacioneDataModel:Observaciones=this.formEditarRecepcione.value;
		ObservacioneDataModel.id=this.dataEditarObservaciones.id;
		this.dataEditarObservaciones.recepcione_id=ObservacioneDataModel.recepcione_id;
		this.servicioObservacione.actulizarObservaciones(ObservacioneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarRecepcione=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editObservacioneDescripcionEnable(){
		this.editarDescripcion=true;
		this.formEditarDescripcion=this.fb.group({
			descripcion:[this.dataEditarObservaciones.descripcion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(40)])],
		});
	}

	editObservacioneDescripcionDisable(){
		this.editarDescripcion=false;
	}

	saveObservacioneDescripcion(){
		let ObservacioneDataModel:Observaciones=this.formEditarDescripcion.value;
		ObservacioneDataModel.id=this.dataEditarObservaciones.id;
		this.dataEditarObservaciones.descripcion=ObservacioneDataModel.descripcion;
		this.servicioObservacione.actulizarObservaciones(ObservacioneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarDescripcion=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "40%");
		}else{
			this.dialogRef.updateSize("100%", "70%");
		}
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};