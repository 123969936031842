import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Factura} from './factura.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class FacturaServices {

	constructor(private http: HttpClient) { }

	obtenerDatosFactura():Observable<Factura[]>{
		return this.http.get<Factura[]>(Globals.BASE_URL_API_REST+'factura.json');
	}

	guardarFactura(data:Factura){
		return this.http.post(Globals.BASE_URL_API_REST+'factura.json',data);
	}

	eliminarFactura(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'factura/'+id+'.json');
	}

	actulizarFactura(data:Factura){
		 return this.http.patch(Globals.BASE_URL_API_REST+'factura/'+data.id+'.json',data);
	}

	verFactura(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'factura/'+id+'.json');
	}
}