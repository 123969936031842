import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { FotoTecnicosServices } from '../fototecnico/fototecnicos.services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	selector: 'app-add-foto-tecnico',
	templateUrl: './fototecnico.compente.html',
	styleUrls: ['./fototecnico.compente.scss']
})

export class FotoTecnicosComponent implements OnInit {
    
	constructor(private fb: FormBuilder,
        private servicioFotoTecnicos:FotoTecnicosServices,
        public dialogRef: MatDialogRef <FotoTecnicosComponent>,
        @Inject(MAT_DIALOG_DATA) public Tecnico: any,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		console.log(this.Tecnico);
        
	}


	onFileChange(event) {
    if(event.target.files.length > 0) {
        var file = event.target.files[0];
        var formData = new FormData();
        
        formData.append('filename', file);
        formData.append('tecnico_id',this.Tecnico.id);
        
        this.servicioFotoTecnicos.saveFotoTecnico(formData).subscribe((data:any)=>{
        if(data.estado){
            this.Noti.setNotification(data).then((datas)=>{
                if(datas){
                    this.dialogRef.close(1);
                }
            }); 
            
        }else{
            this.Noti.setNotification(data);
        }
        });
    }
    }

}