import { Routes } from '@angular/router';
import {AuthGuard} from '../../appauth/auth/auth.guard';
import { RepuestoservicioListaComponent } from './view-repuestoservicio/repuestoserviciovista.component';
export const RepuestoserviciosRoutes: Routes = [{
	path: '',
	redirectTo: 'repuestoservicio-lista',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'repuestoservicio-lista',
		component: RepuestoservicioListaComponent,
		canActivate: [AuthGuard]
	}]
}];
