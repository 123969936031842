import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Tecnicos} from './tecnicos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class TecnicosServices {

	constructor(private http: HttpClient) { }

	obtenerDatosTecnicos():Observable<Tecnicos[]>{
		return this.http.get<Tecnicos[]>(Globals.BASE_URL_API_REST+'tecnicos.json');
	}

	guardarTecnicos(data:Tecnicos){
		return this.http.post(Globals.BASE_URL_API_REST+'tecnicos.json',data);
	}

	eliminarTecnicos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'tecnicos/'+id+'.json');
	}

	actulizarTecnicos(data:Tecnicos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'tecnicos/'+data.id+'.json',data);
	}

	verTecnicos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'tecnicos/'+id+'.json');
	}
}