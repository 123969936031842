import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { ValoracionesServices } from 'app/appmantenimiento/reparar-valoraciones/modelo-valoracione/servicios-valoraciones.services';

@Component({
	selector: 'app-insertarasignacionemodal',
	templateUrl: './insertarasignacionemodal.component.html',
	styleUrls: ['./insertarasignacionemodal.component.scss']
})

export class InsertarAsignacioneModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public formInsertarAsignacione: FormGroup;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarAsignaciones: any,
		public dialogRef: MatDialogRef <InsertarAsignacioneModalComponent>,
		private servicioValoraciones:ValoracionesServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
		console.log(this.dataEditarAsignaciones);
		
		
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.formInsertarAsignacione = this.fb.group({
			estado:[this.dataEditarAsignaciones.estado,null],
			detalle:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(500)])],
			asignacione_id:[this.dataEditarAsignaciones.id,Validators.compose([Validators.required,CustomValidators.number])],
			repuestoservicio_id:[this.dataEditarAsignaciones.problema.repuestoservicio_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}


	saveAsignacione(){
		let asignacioneDataModel:any=this.formInsertarAsignacione.value;
		asignacioneDataModel.recepcione_id=this.dataEditarAsignaciones.recepcion.id;
		this.servicioValoraciones.guardarValoraciones(asignacioneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.dialogRef.close(1);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}


	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};