import { Component, OnInit,ViewEncapsulation,Inject } from '@angular/core';
import {  MatDialogRef,MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { Users } from '../../user/users.model';
import { CustomValidators } from 'ng2-validation';
import {UserServices} from '../../user/users.services';
import {NotificationServices} from '../../../notification/notificatio.services';
import {GroupsServices} from '../../../group-pages/groups/groups.services';

@Component({
    selector: 'app-edit-user',
    templateUrl:'./usereditmodal-component.html',
    styleUrls: ['./usereditmodal-component.scss'],
    encapsulation: ViewEncapsulation.None
  })
  export class UserEditModalComponent implements OnInit {
   
    breakpoint: number;
    
    public formGroupFullname: FormGroup;
    public editFullname:boolean=false;

    public formGroupUserName: FormGroup;
    public editUserName:boolean=false;

    public formGroupUserIdentificador: FormGroup;
    public editIdentificador:boolean=false;

    public selectedGroup:number;

    public formGroupUserGrupo: FormGroup;
    public editGrupo:boolean=false;

    
    ratio = '4:1';
    groupdata:any;
    constructor(public dialogRef: MatDialogRef <UserEditModalComponent>,
                private fb: FormBuilder,
                private servicesUser:UserServices,
                @Inject(MAT_DIALOG_DATA) public dataUserEditDialog: any,
                private servicesGroup:GroupsServices,
                private Noti:NotificationServices,) {}
    
    ngOnInit(){
      
      this.selectedGroup=this.dataUserEditDialog.group_id;

      this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
      this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
      if(this.breakpoint!=1){
        this.dialogRef.updateSize("50%", "45%");
      }else{
        this.dialogRef.updateSize("100%", "80%");
      }
      
      this.servicesGroup.getDataGroups().subscribe((data:any)=>{
        this.groupdata=data.groups;
      });

      this.formGroupFullname = this.fb.group({
        fullname: [this.dataUserEditDialog.fullname, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(250)])]
      });

      this.formGroupUserName = this.fb.group({
        username: [this.dataUserEditDialog.username,Validators.compose([Validators.required, CustomValidators.email])]
      });

      this.formGroupUserIdentificador = this.fb.group({
        identificador: [this.dataUserEditDialog.identificador,null]
      });
      
      this.formGroupUserGrupo = this.fb.group({
        group_id: [this.dataUserEditDialog.group_id, Validators.compose([Validators.required])]
      });
  
    }

    //editar username
    editUserNameEnable(){
      this.editUserName=true;
      this.formGroupUserName = this.fb.group({
        username: [this.dataUserEditDialog.username,Validators.compose([Validators.required, CustomValidators.email])]
      });
    }

    editUserNameDisable(){
      this.editUserName=false;
    }

    saveUserName(){
      
      let userDataModel:Users=this.formGroupUserName.value;
      userDataModel.id=this.dataUserEditDialog.id;
      this.dataUserEditDialog.username=userDataModel.username;
      this.servicesUser.updateUser(userDataModel).subscribe((data:any)=>{
        if(data.estado){
          this.Noti.setNotification(data).then((datas)=>{
              if(datas){
                this.editUserName=false;
              }
          });
        }else{
          this.Noti.setNotification(data);
        }
      });

    }


    //editar fullname
    editFullNameEnable(){
      this.editFullname=true;
      this.formGroupFullname = this.fb.group({
        fullname: [this.dataUserEditDialog.fullname, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(250)])]
      });
    }

    editFullNameDisable(){
      this.editFullname=false;
    }

    saveUserFullname(){
      let userDataModel:Users=this.formGroupFullname.value;
      userDataModel.id=this.dataUserEditDialog.id;
      this.dataUserEditDialog.fullname=userDataModel.fullname;
      this.servicesUser.updateUser(userDataModel).subscribe((data:any)=>{
        if(data.estado){
          this.Noti.setNotification(data).then((datas)=>{
              if(datas){
                this.editFullname=false;
              }
          });
        
        }else{
          this.Noti.setNotification(data);
        }
      });
    }

    //editar identificador

    editIdentificadorEnable(){
      this.editIdentificador=true;
      this.formGroupUserIdentificador = this.fb.group({
        identificador: [this.dataUserEditDialog.identificador,null]
      });
    }

    editIdentificadorDisable(){
      this.editIdentificador=false;
    }

    saveUserIdentificador(){
      let userDataModel:Users=this.formGroupUserIdentificador.value;
      userDataModel.id=this.dataUserEditDialog.id;
      this.dataUserEditDialog.identificador=userDataModel.identificador;
      this.servicesUser.updateUser(userDataModel).subscribe((data:any)=>{
        if(data.estado){
          this.Noti.setNotification(data).then((datas)=>{
              if(datas){
                this.editIdentificador=false;
              }
          });
        
        }else{
          this.Noti.setNotification(data);
        }
      });
    }
    //Editar grupo

    editGrupoEnable(){
      
      this.editGrupo=true;
      this.selectedGroup=this.dataUserEditDialog.group_id;
      
      this.formGroupUserGrupo = this.fb.group({
        group_id: [this.dataUserEditDialog.group_id, Validators.compose([Validators.required])]
      });

    }

    editGrupoDisable(){
      this.editGrupo=false;
      this.selectedGroup=this.dataUserEditDialog.group_id;
      console.log(this.dataUserEditDialog.group_id);
      
    }

    saveUserGrupo(){
      let userDataModel:Users=this.formGroupUserGrupo.value;
      userDataModel.id=this.dataUserEditDialog.id;
      this.dataUserEditDialog.group_id=userDataModel.grupo_id;
      this.servicesUser.updateUser(userDataModel).subscribe((data:any)=>{
        if(data.estado){
          this.Noti.setNotification(data).then((datas)=>{
              if(datas){
                this.editGrupo=false;
                this.dialogRef.close(1);
              }
          });
        
        }else{
          this.Noti.setNotification(data);
        }
      });
    }


    onResize(event) {
      this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
      this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
      if(this.breakpoint!=1){
        this.dialogRef.updateSize("50%", "45%");
      }else{
        this.dialogRef.updateSize("100%", "80%");
      }
    }

    cerrarModal(){
      this.dialogRef.close(2);
    }
}
  
  MatDialogRef.prototype.updateSize = function (width, height) {
    if (width === void 0) { width = 'auto'; }
    if (height === void 0) { height = 'auto'; }
    this._getPositionStrategy().width(width).height(height);
    this._overlayRef.updatePosition();
    return this;
  };
  