import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { FotoEquiposServices } from '../fotoequipo/fotoequipos.services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	selector: 'app-add-foto-equipo',
	templateUrl: './fotoequipo.compente.html',
	styleUrls: ['./fotoequipo.compente.scss']
})

export class FotoEquiposComponent implements OnInit {
    
	constructor(private fb: FormBuilder,
        private servicioFotoEquipos:FotoEquiposServices,
        public dialogRef: MatDialogRef <FotoEquiposComponent>,
        @Inject(MAT_DIALOG_DATA) public Equipo: any,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		console.log(this.Equipo);
        
	}


	onFileChange(event) {
    if(event.target.files.length > 0) {
        var file = event.target.files[0];
        var formData = new FormData();
        
        formData.append('filename', file);
        formData.append('equipo_id',this.Equipo.id);
        
        this.servicioFotoEquipos.saveFotoEquipo(formData).subscribe((data:any)=>{
        if(data.estado){
            this.Noti.setNotification(data).then((datas)=>{
                if(datas){
                    this.dialogRef.close(1);
                }
            }); 
            
        }else{
            this.Noti.setNotification(data);
        }
        });
    }
    }

}