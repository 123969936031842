import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {MatInputModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatListModule,
		MatGridListModule,
		MatProgressBarModule,
		MatMenuModule,
		MatButtonToggleModule,
		MatToolbarModule, 
		MatTabsModule} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ProblemasRoutes } from './problemas.routing';
import { ProblemaListaComponent } from './view-problema/problemavista.component';
import { InfoproblemasComponent } from './infoproblemas/infoproblemas.component';
@NgModule({
	imports: [
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	RouterModule.forChild(ProblemasRoutes),
	MatIconModule,
	MatCardModule,
	MatButtonModule,
	ReactiveFormsModule,
	MatListModule,
	MatProgressBarModule,
	MatMenuModule,
	MatToolbarModule,
	FlexLayoutModule,
	MatInputModule,
	MatButtonToggleModule,
	MatGridListModule,
	NgxDatatableModule,
	MatTabsModule
],
declarations: [
	ProblemaListaComponent,
	InfoproblemasComponent
],
exports: [
	ProblemaListaComponent,
	InfoproblemasComponent
]
})
export class ProblemasModule {}
