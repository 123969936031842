import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {MatInputModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatListModule,
		MatGridListModule,
		MatProgressBarModule,
		MatMenuModule,
		MatButtonToggleModule,
		MatTabsModule,
		MatSelectModule,
		MatTableModule,
		MatRadioModule,
		MatToolbarModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EditarAsignacioneModalComponent } from './editar/editarasignacionemodal.component';
import { InsertarAsignacioneModalComponent } from './insertar/insertarasignacionemodal.component';
import { ClientesModule } from 'app/appmantenimiento/reparar-clientes/clientes.module';
import { RecepcionistasModule } from 'app/appmantenimiento/reparar-recepcionistas/recepcionistas.module';
import { ProblemasModule } from 'app/appmantenimiento/reparar-problemas/problemas.module';
import { ObservacionesModule } from 'app/appmantenimiento/reparar-observaciones/observaciones.module';
import { NgxBarcodeModule } from 'ngx-barcode';
@NgModule({
	declarations: [
		EditarAsignacioneModalComponent,
		InsertarAsignacioneModalComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatListModule,
		ReactiveFormsModule,
		MatProgressBarModule,
		MatMenuModule,
		MatToolbarModule,
		FlexLayoutModule,
		MatButtonToggleModule,
		MatGridListModule,
		MatSelectModule,
		MatRadioModule,
		MatTabsModule,
		MatTableModule,
		NgxDatatableModule,
		ClientesModule,
		RecepcionistasModule,
		ProblemasModule,
		ObservacionesModule,
		NgxBarcodeModule
	],
	entryComponents: [
		EditarAsignacioneModalComponent,
		InsertarAsignacioneModalComponent,
	]
})
export class AsignacionesModalModule {}
