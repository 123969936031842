import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Equipoclientes } from '../../modelo-equipocliente/equipoclientes.model';
import { EquipoclientesServices } from '../../modelo-equipocliente/servicios-equipoclientes.services';
import { Equipos } from '../../../reparar-equipos/modelo-equipo/equipos.model';
import { EquiposServices } from '../../../reparar-equipos/modelo-equipo/servicios-equipos.services';
import { Clientes } from '../../../reparar-clientes/modelo-cliente/clientes.model';
import { ClientesServices } from '../../../reparar-clientes/modelo-cliente/servicios-clientes.services';

@Component({
	selector: 'app-editarequipoclientemodal',
	templateUrl: './editarequipoclientemodal.component.html',
	styleUrls: ['./editarequipoclientemodal.component.scss']
})

export class EditarEquipoclienteModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataEquipos:any;
	public dataClientes:any;

	public formEditarEquipo:FormGroup;
	public editarEquipo:boolean=false;
	public seleccionarEquipo:number;

	public formEditarCliente:FormGroup;
	public editarCliente:boolean=false;
	public seleccionarCliente:number;

	constructor(private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataEditarEquipoclientes: any,
		public dialogRef: MatDialogRef <EditarEquipoclienteModalComponent>,
		private servicioEquipocliente:EquipoclientesServices,
		private servicioEquipo:EquiposServices,
		private servicioCliente:ClientesServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.seleccionarEquipo=this.dataEditarEquipoclientes.equipo_id;
		this.servicioEquipo.obtenerDatosEquipos().subscribe((data:any)=>{
			this.dataEquipos=data.equipos;
		});
		this.formEditarEquipo= this.fb.group({
			equipo_id:[this.dataEditarEquipoclientes.equipo_id,Validators.compose([Validators.required])]
		});
	
		this.seleccionarCliente=this.dataEditarEquipoclientes.cliente_id;
		this.servicioCliente.obtenerDatosClientes().subscribe((data:any)=>{
			this.dataClientes=data.clientes;
		});
		this.formEditarCliente= this.fb.group({
			cliente_id:[this.dataEditarEquipoclientes.cliente_id,Validators.compose([Validators.required])]
		});
	
	}

	editEquipoclienteEquipoEnable(){
		this.editarEquipo=true;
		this.seleccionarEquipo=this.dataEditarEquipoclientes.equipo_id;
		this.formEditarEquipo=this.fb.group({
			equipo_id:[this.dataEditarEquipoclientes.equipo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editEquipoclienteEquipoDisable(){
		this.seleccionarEquipo=this.dataEditarEquipoclientes.equipo_id;
		this.editarEquipo=false;
	}

	saveEquipoclienteEquipo(){
		let EquipoclienteDataModel:Equipoclientes=this.formEditarEquipo.value;
		EquipoclienteDataModel.id=this.dataEditarEquipoclientes.id;
		this.dataEditarEquipoclientes.equipo_id=EquipoclienteDataModel.equipo_id;
		this.servicioEquipocliente.actulizarEquipoclientes(EquipoclienteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarEquipo=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	editEquipoclienteClienteEnable(){
		this.editarCliente=true;
		this.seleccionarCliente=this.dataEditarEquipoclientes.cliente_id;
		this.formEditarCliente=this.fb.group({
			cliente_id:[this.dataEditarEquipoclientes.cliente_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editEquipoclienteClienteDisable(){
		this.seleccionarCliente=this.dataEditarEquipoclientes.cliente_id;
		this.editarCliente=false;
	}

	saveEquipoclienteCliente(){
		let EquipoclienteDataModel:Equipoclientes=this.formEditarCliente.value;
		EquipoclienteDataModel.id=this.dataEditarEquipoclientes.id;
		this.dataEditarEquipoclientes.cliente_id=EquipoclienteDataModel.cliente_id;
		this.servicioEquipocliente.actulizarEquipoclientes(EquipoclienteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.editarCliente=false;
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};