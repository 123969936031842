import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Dispositivos } from '../../modelo-dispositivo/dispositivos.model';
import { DispositivosServices } from '../../modelo-dispositivo/servicios-dispositivos.services';

@Component({
	selector: 'app-insertardispositivomodal',
	templateUrl: './insertardispositivomodal.component.html',
	styleUrls: ['./insertardispositivomodal.component.scss']
})

export class InsertarDispositivoModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public formInsertarDispositivo: FormGroup;

	constructor(private fb: FormBuilder,
		public dialogRef: MatDialogRef <InsertarDispositivoModalComponent>,
		private servicioDispositivo:DispositivosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "35%");
		}else{
			this.dialogRef.updateSize("100%", "50%");
		}
	
	
		this.formInsertarDispositivo = this.fb.group({
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
			estado:[1,null],
		});
	}


	saveDispositivo(){
		let dispositivoDataModel:Dispositivos=this.formInsertarDispositivo.value;
		this.servicioDispositivo.guardarDispositivos(dispositivoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.dialogRef.close(1);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}


	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "35%");
		}else{
			this.dialogRef.updateSize("100%", "50%");
		}
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};