import { Routes } from '@angular/router';
import {AuthGuard} from '../../appauth/auth/auth.guard';
import { ClienteListaComponent } from './view-cliente/clientevista.component';
export const ClientesRoutes: Routes = [{
	path: '',
	redirectTo: 'cliente-lista',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'cliente-lista',
		component: ClienteListaComponent,
		canActivate: [AuthGuard]
	}]
}];
