import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Equipoclientes } from '../../modelo-equipocliente/equipoclientes.model';
import { EquipoclientesServices } from '../../modelo-equipocliente/servicios-equipoclientes.services';
import { Equipos } from '../../../reparar-equipos/modelo-equipo/equipos.model';
import { EquiposServices } from '../../../reparar-equipos/modelo-equipo/servicios-equipos.services';
import { Clientes } from '../../../reparar-clientes/modelo-cliente/clientes.model';
import { ClientesServices } from '../../../reparar-clientes/modelo-cliente/servicios-clientes.services';

@Component({
	selector: 'app-insertarequipoclientemodal',
	templateUrl: './insertarequipoclientemodal.component.html',
	styleUrls: ['./insertarequipoclientemodal.component.scss']
})

export class InsertarEquipoclienteModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataEquipos:any;
	public dataClientes:any;
	public formInsertarEquipocliente: FormGroup;

	constructor(private fb: FormBuilder,
		public dialogRef: MatDialogRef <InsertarEquipoclienteModalComponent>,
		private servicioEquipocliente:EquipoclientesServices,
		private servicioEquipo:EquiposServices,
		private servicioCliente:ClientesServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.servicioEquipo.obtenerDatosEquipos().subscribe((data:any)=>{
			this.dataEquipos=data.equipos;
		});
		this.servicioCliente.obtenerDatosClientes().subscribe((data:any)=>{
			this.dataClientes=data.clientes;
		});
	
		this.formInsertarEquipocliente = this.fb.group({
			equipo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			cliente_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	saveEquipocliente(){
		let equipoclienteDataModel:Equipoclientes=this.formInsertarEquipocliente.value;
		this.servicioEquipocliente.guardarEquipoclientes(equipoclienteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.dialogRef.close(1);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}


	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};