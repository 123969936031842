import { Component, OnInit, Input, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../global/global.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { EditarEquipoclienteModalComponent } from 'app/appmantenimiento/reparar-equipoclientes/modales-equipocliente/editar/editarequipoclientemodal.component';

@Component({
	selector: 'app-imprimir-multa',
	templateUrl: './imprimir.component.html',
	styleUrls: ['./imprimir.component.scss']
})

export class ImprimirComponent implements OnInit {
    pdfpath:any="";

    constructor(@Inject(MAT_DIALOG_DATA) public rutaPDF: any,
    public dialogRef: MatDialogRef <ImprimirComponent>,
    public sanitizer: DomSanitizer) {}

    ngOnInit(){
        this.pdfpath=this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST+this.rutaPDF); 
    }

    cerrarModal(){
        this.dialogRef.close(2);
    }
}