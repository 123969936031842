import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Repuestoservicios } from '../../modelo-repuestoservicio/repuestoservicios.model';
import { RepuestoserviciosServices } from '../../modelo-repuestoservicio/servicios-repuestoservicios.services';
import { Modelos } from '../../../reparar-modelos/modelo-modelo/modelos.model';
import { ModelosServices } from '../../../reparar-modelos/modelo-modelo/servicios-modelos.services';
import * as moment from 'moment';

@Component({
	selector: 'app-insertarrepuestoserviciomodal',
	templateUrl: './insertarrepuestoserviciomodal.component.html',
	styleUrls: ['./insertarrepuestoserviciomodal.component.scss']
})

export class InsertarRepuestoservicioModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	valueTiempo:number=0;
	public formInsertarRepuestoservicio: FormGroup;

	constructor(private fb: FormBuilder,
		public dialogRef: MatDialogRef <InsertarRepuestoservicioModalComponent>,
		@Inject(MAT_DIALOG_DATA) public dataModelo: any,
		private servicioRepuestoservicio:RepuestoserviciosServices,
		private servicioModelo:ModelosServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {

		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}

		if(this.dataModelo!=undefined){
			this.formInsertarRepuestoservicio = this.fb.group({
				nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
				estado:[1,null],
				tipo:[1,Validators.compose([Validators.required,CustomValidators.number])],
				tiempo:[null,Validators.compose([Validators.required])],
				modelo_id:[this.dataModelo.id,Validators.compose([Validators.required,CustomValidators.number])],
				min:[0,Validators.compose([Validators.required,CustomValidators.number])],
				precio:[null,Validators.compose([Validators.required,CustomValidators.number])],
			});
		}
	}
	saveRepuestoservicio(){
		let repuestoservicioDataModel:any=this.formInsertarRepuestoservicio.value;
		if(repuestoservicioDataModel.tipo==1){
			repuestoservicioDataModel.tiempo= moment('2000-01-01').add(moment.duration(repuestoservicioDataModel.tiempo+':'+repuestoservicioDataModel.min));
			repuestoservicioDataModel.tiempo= moment(repuestoservicioDataModel.tiempo).format('YYYY-MM-DD HH:mm:ss')
		}else if(repuestoservicioDataModel.tipo==2){
			repuestoservicioDataModel.tiempo=moment('2000-01-01').add(repuestoservicioDataModel.tiempo, 'days');
		}else{
			repuestoservicioDataModel.tiempo=moment('2000-01-01').add(repuestoservicioDataModel.tiempo*7, 'days');
		}
		this.servicioRepuestoservicio.guardarRepuestoservicios(repuestoservicioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						let dataReturn = {op:1,datos:data.datos}
						this.dialogRef.close(dataReturn);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}

	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	setValor(val:number){
		this.valueTiempo=val;
	}

	ceros(e:any){
		if (e.target.value.length >= 2) e.target.value = e.target.value.slice(0, 2);
		if (e.target.value.length === 1) e.target.value = '0' + e.target.value;
		if (!e.target.value) e.target.value = '00';
	}
	maximo(e:any){
		if (e.target.value > 5) e.target.value = 5;
		if (e.target.value < 1) e.target.value = 1;
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};