import { Component, OnInit, Input } from '@angular/core';
import { Globals } from 'app/global/global.model';

@Component({
  selector: 'app-inforecepcionista',
  templateUrl: './inforecepcionista.component.html',
  styleUrls: ['./inforecepcionista.component.scss']
})
export class InforecepcionistaComponent implements OnInit {

  @Input() dataRecepcionista:any;

  public imgUrlTecnicos:string;
  constructor() { }

  ngOnInit() {
    this.imgUrlTecnicos=Globals.BASE_URL_API_REST;
  }


}
