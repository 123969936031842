import { Component, OnInit,ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices} from '../../../appauth/notification/notificatio.services';
import { Problemas } from '../modelo-problema/problemas.model';
import { ProblemasServices } from '../modelo-problema/servicios-problemas.services';
import { InsertarProblemaModalComponent } from '../modales-problema/insertar/insertarproblemamodal.component';
import { EditarProblemaModalComponent } from '../modales-problema/editar/editarproblemamodal.component';
import { Recepciones } from 'app/appmantenimiento/reparar-recepciones/modelo-recepcione/recepciones.model';
import { Modelos } from 'app/appmantenimiento/reparar-modelos/modelo-modelo/modelos.model';
import * as moment from 'moment';

@Component({
	selector: 'ms-problemavista',
	templateUrl:'./problemavista.component.html',
	styleUrls: ['./problemavista.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ProblemaListaComponent implements OnInit {

	@Input() Recepcione:Recepciones;
	@Input() Modelo:Modelos;

	rows = [];
	temp = [];
	minDisplay:any;
	horaDisplay:any;
	diasDisplay:any;
	semanaDisplay:any;
	totalCosto:number=0;

	iconItems = [{
		text: 'Nuevo',
		icon: 'add'
	},{
		text: 'Refrescar',
		icon: 'refresh'
	}];

	config: MatDialogConfig = {
		disableClose: false,
		width: '70%',
		height: '80%',
		position: {
			top: '5%',
			bottom: '',
			left: '',
			right: ''
		},
		data: { datasend: null }
	};

	select(text: string) {
		if(text=='Refrescar'){
			if(this.Recepcione!=undefined){
				this.listaProblemas();
			}
		}else if(text=='Nuevo'){
			this.openProblema();
		}
	}

	constructor(private serviciosProblemas:ProblemasServices,
		public dialog: MatDialog,
		public mensajes:NotificationServices,
	){}

	ngOnInit(){
		if(this.Recepcione!=undefined){
			this.listaProblemas();
		}
	}

	listaProblemas(){
		this.serviciosProblemas.verProblemasRecepcion(this.Recepcione).subscribe((data:any)=>{
			
			data.problemas.forEach((element,index )=> {
				data.problemas[index].repuestoservicio.valor=0;
				if(element.repuestoservicio.tipo==2){
					let fecha1 = moment('2000-01-01');
					let fecha2 = moment(element.repuestoservicio.tiempo);
					data.problemas[index].repuestoservicio.valor=fecha2.diff(fecha1, 'days');

				}else if(element.repuestoservicio.tipo==3){
					let fecha1 = moment('2000-01-01');
					let fecha2 = moment(element.repuestoservicio.tiempo);
					data.problemas[index].repuestoservicio.valor=fecha2.diff(fecha1, 'days')/7;
				}
			},this);

			this.temp = [...data.problemas];
			this.rows=data.problemas;

			this.suma();
			this.tiempo();
		});
	}

	openProblema() {
		let datos={'estado':true,'tipomsg':'warning','msg':{'ok':'Confirmar la revisión del equipo <code>Antes de asiganar Observaciones</code>'}};
		this.config.data=this.Recepcione;
		if(this.Recepcione!=undefined){
			let dataSendToInser = {recepcion:this.Recepcione,modelo:this.Modelo} as any;
			this.config.data=dataSendToInser;
			let dialogRef = this.dialog.open(InsertarProblemaModalComponent, this.config);
			dialogRef.afterClosed().subscribe(result => {
				if(result==1){
					this.listaProblemas();
					this.suma();
					this.tiempo();
				}
			});
		}else{
			this.mensajes.setNotification(datos);
		}
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter((d)=> {
			console.log(d);
			
			return d.repuestoservicio.nombre.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}

	deleteProblema(dataProblema:any){
		let data={'val':dataProblema.repuestoservicio.nombre,'msg':'Eliminar Problema'};
		this.mensajes.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosProblemas.eliminarProblemas(dataProblema.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.mensajes.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.listaProblemas();
								this.suma();
								this.tiempo();
							}
						});
					}else{
						this.mensajes.setNotification(smsdata);
					}
				});
			}
		});
	}

	editProblema(data:any) {
		this.config.data=data;
		let dialogRef = this.dialog.open(EditarProblemaModalComponent, this.config);
		dialogRef.afterClosed().subscribe(result => {
			if(result==1){
				this.listaProblemas();
				this.suma();
				this.tiempo();
			}
		});
	}

	suma(){
		this.totalCosto=0;
		this.rows.forEach(element => {
			this.totalCosto+=element.repuestoservicio.precio;
		});
	}

	tiempo(){

		let min=0;
		let hora=0;
		let dias=0;
		let seman=0;
		this.rows.forEach(element => {
			if(element.repuestoservicio.tipo==1){
				let tmpd= moment.utc(element.repuestoservicio.tiempo).format('YYYY-MM-DD HH:mm:ss')
				let resul =(tmpd.split(' ')[1].split(':'));
				min+=Number(resul[1]);
				hora+=Number(resul[0]);
			} 
			if(element.repuestoservicio.tipo==2){
				dias+=element.repuestoservicio.valor;
			}
			if(element.repuestoservicio.tipo==3){
				seman+=element.repuestoservicio.valor;
			}
		});

		
		if(min>59){
			let mt=0,v=0,v1=0;	
			v=Math.trunc(min/60);
			v1=v*60;
			mt=min-v1;
			hora=hora+v;
			min=mt;
		}	
		if(hora>8){
			let ht=0,v=0,v1=0;	
			v=Math.trunc(hora/8);
			v1=v*8;
			ht=hora-v1;
			dias=dias+v;
			hora=ht;
		}
		if(dias>5){
			let st=0,v=0,v1=0;	
			v=Math.trunc(dias/5);
			v1=v*5;
			st=dias-v1;
			seman=seman+v;
			dias=st;
		}

		this.minDisplay=min;
		this.horaDisplay=hora;
		this.diasDisplay=dias;
		this.semanaDisplay=seman;
	}

}