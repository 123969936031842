import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Modelos } from '../../modelo-modelo/modelos.model';
import { ModelosServices } from '../../modelo-modelo/servicios-modelos.services';
import { Marcas } from '../../../reparar-marcas/modelo-marca/marcas.model';
import { MarcasServices } from '../../../reparar-marcas/modelo-marca/servicios-marcas.services';
import { DispositivosServices } from 'app/appmantenimiento/reparar-dispositivos/modelo-dispositivo/servicios-dispositivos.services';
import { Dispositivos } from 'app/appmantenimiento/reparar-dispositivos/modelo-dispositivo/dispositivos.model';

@Component({
	selector: 'app-insertarmodelomodal',
	templateUrl: './insertarmodelomodal.component.html',
	styleUrls: ['./insertarmodelomodal.component.scss']
})

export class InsertarModeloModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public formInsertarModelo: FormGroup;
	rows = [];
	temp = [];
	rowms = [];
	tempm = [];
	verDispositivo:boolean=true;
	verMarca:boolean=false;
	verInsertar:boolean=false;
	pathdispositivo:string="";
	pathmaraca:string="";
	pathfull:string="";
	dataDispositivoTable:Dispositivos;
	dataMarcaInsert:Dispositivos;
	
	constructor(private fb: FormBuilder,
		public dialogRef: MatDialogRef <InsertarModeloModalComponent>,
		private servicioModelo:ModelosServices,
		private serviciosDispositivos:DispositivosServices,
		private servicioMarca:MarcasServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
		this.listaDispositivos();
	}

	selectDispocitivo(data:any){
		this.verDispositivo=false;
		this.verMarca=true;
		this.verInsertar=false;
		this.pathdispositivo=data.nombre;
		this.pathfull=this.pathdispositivo;
		this.dataDispositivoTable=data;
		this.servicioMarca.verMarcasPorDispositivos(data).subscribe((data:any)=>{
			this.rowms=data.marcas;
			this.tempm = [...this.rowms];
		});
	}

	gotoDispositivo(){
		this.verDispositivo=true;
		this.verMarca=false;
		this.verInsertar=false;
		this.rowms=[];
		this.tempm = [...this.rowms];
	}

	gotoMarca(){
		this.verDispositivo=false;
		this.verMarca=true;
		this.verInsertar=false;
		this.rowms=[];
		this.tempm = [...this.rowms];
		this.selectDispocitivo(this.dataDispositivoTable);
	}

	gotoInsert(){
		this.verDispositivo=false;
		this.verMarca=false;
		this.verInsertar=true;
		this.pathmaraca=this.dataMarcaInsert.nombre;
		this.pathfull=this.pathdispositivo+'/'+this.pathmaraca;
	}

	selectMarca(data:any){
		this.verDispositivo=false;
		this.verMarca=false;
		this.verInsertar=true;
		this.pathmaraca=data.nombre;
		this.pathfull=this.pathdispositivo+'/'+this.pathmaraca;
		this.dataMarcaInsert=data;
		this.formInsertarModelo = this.fb.group({
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(30)])],
			estado:[1,null],
			marca_id:[data.id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}


	saveModelo(){
		let modeloDataModel:Modelos=this.formInsertarModelo.value;
		this.servicioModelo.guardarModelos(modeloDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.dialogRef.close(1);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}


	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	listaDispositivos(){
		this.serviciosDispositivos.obtenerDatosDispositivos().subscribe((data:any)=>{
			this.temp = [...data.dispositivos];
			this.rows=data.dispositivos;
		});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter((d)=> {
			return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
		});
		this.rows = temp;
	}

	updateFilterMarca(event) {
		const valm = event.target.value.toLowerCase();
		const tempm = this.tempm.filter((d)=> {
			return d.nombre.toLowerCase().indexOf(valm) !== -1 || !valm;
		});
		this.rowms = tempm;
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};