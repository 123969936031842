import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Recepciones } from '../../modelo-recepcione/recepciones.model';
import { RecepcionesServices } from '../../modelo-recepcione/servicios-recepciones.services';
import { Equipoclientes } from '../../../reparar-equipoclientes/modelo-equipocliente/equipoclientes.model';
import { EquipoclientesServices } from '../../../reparar-equipoclientes/modelo-equipocliente/servicios-equipoclientes.services';
import { Recepcionistas } from '../../../reparar-recepcionistas/modelo-recepcionista/recepcionistas.model';
import { RecepcionistasServices } from '../../../reparar-recepcionistas/modelo-recepcionista/servicios-recepcionistas.services';

@Component({
	selector: 'app-insertarrecepcionemodal',
	templateUrl: './insertarrecepcionemodal.component.html',
	styleUrls: ['./insertarrecepcionemodal.component.scss']
})

export class InsertarRecepcioneModalComponent implements OnInit {
	breakpoint: number;
	ratio = '4:1';
	public dataEquipoclientes:any;
	public dataRecepcionistas:any;
	public formInsertarRecepcione: FormGroup;

	constructor(private fb: FormBuilder,
		public dialogRef: MatDialogRef <InsertarRecepcioneModalComponent>,
		private servicioRecepcione:RecepcionesServices,
		private servicioEquipocliente:EquipoclientesServices,
		private servicioRecepcionista:RecepcionistasServices,
		private mensajes:NotificationServices,) { }

	ngOnInit() {
	
		this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
		this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	
		this.servicioEquipocliente.obtenerDatosEquipoclientes().subscribe((data:any)=>{
			this.dataEquipoclientes=data.equipoclientes;
		});
		this.servicioRecepcionista.obtenerDatosRecepcionistas().subscribe((data:any)=>{
			this.dataRecepcionistas=data.recepcionistas;
		});
	
		this.formInsertarRecepcione = this.fb.group({
			estado:[1,null],
			equipocliente_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			recepcionista_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}


	saveRecepcione(){
		let recepcioneDataModel:Recepciones=this.formInsertarRecepcione.value;
		this.servicioRecepcione.guardarRecepciones(recepcioneDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.mensajes.setNotification(data).then((datas)=>{
					if(datas){
						this.dialogRef.close(1);
					}
				});
			}else{
				this.mensajes.setNotification(data);
			}
		});
	}


	onResize(event){
		this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
		this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
		if(this.breakpoint!=1){
			this.dialogRef.updateSize("50%", "60%");
		}else{
			this.dialogRef.updateSize("100%", "80%");
		}
	}

	cerrarModal(){
		this.dialogRef.close(2);
	}
}

MatDialogRef.prototype.updateSize = function (width, height) {
	if (width === void 0) { width = 'auto'; }
	if (height === void 0) { height = 'auto'; }
	this._getPositionStrategy().width(width).height(height);
	this._overlayRef.updatePosition();
	return this;
};