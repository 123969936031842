import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../global/global.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class FotoClientesServices {

	constructor(private http: HttpClient) { }

	saveFotoCliente(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'fotoclientes.json',data);
	}

	deleteFotoCliente(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'fotoclientes/'+id+'.json');
	}


}