import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Clientes} from './clientes.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class ClientesServices {

	constructor(private http: HttpClient) { }

	obtenerDatosClientes():Observable<Clientes[]>{
		return this.http.get<Clientes[]>(Globals.BASE_URL_API_REST+'clientes.json');
	}

	guardarClientes(data:Clientes){
		return this.http.post(Globals.BASE_URL_API_REST+'clientes.json',data);
	}

	eliminarClientes(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'clientes/'+id+'.json');
	}

	actulizarClientes(data:Clientes){
		 return this.http.patch(Globals.BASE_URL_API_REST+'clientes/'+data.id+'.json',data);
	}

	verClientes(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'clientes/'+id+'.json');
	}

	verClientesBusacar(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listadeclientes.json',data);
	}
	   
	verClientesBusacarRealizadas(data:any){
		return this.http.put(Globals.BASE_URL_API_REST+'listadeclientes.json',data);
   	}

	verClientesBusacarRealizadasCodigo(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listadeclientescodigo.json',data);
	}

	buscarClientesPorClientes(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listabuscar.json',data);
	}

}